import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/auth';
import { Observable, Subject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { unsubscribe } from 'src/app/core/utils';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AuthDialogComponent } from 'src/app/partials/auth/auth-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { Route, Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-borrow-registration',
  templateUrl: './borrow-registration.component.html',
  styleUrls: ['./borrow-registration.component.scss'],
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    CommonModule,
    CheckboxModule,
    RadioButtonModule,
    AuthDialogComponent,
    TranslateModule,
    PrimeNgModule,
  ],
})
export class BorrowRegistrationComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  companyType: any[] = [];
  fundsFor: any[] = [];
  loanTenureO: any[] = [];
  hearAboutUsO: any[] = [];
  User:"";
email:string;
phone :string;
  activeStep: number = 1;
  authDialogVisible: boolean = false;
  activeIndex: number | null;
  isAuthenticated: boolean;
  selectedFileName: string | null = null;

  registrationsSuccess: boolean = false;

  submitUserType: any;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _navigate: Router,

  ) {
    this.checkIfUserAlreadyHaveBorrowerAccount()
  }

  ngOnInit() {
    this.userInfo();
    const getUserType: any = localStorage.getItem('profileType');

    const parsedData = getUserType ? JSON.parse(getUserType) : null;



    if (parsedData) {
      this.submitUserType = parsedData;
    } else {
      this.submitUserType = { investor: false, borrower: false };
    }

    this.companyType = [
      { name: '' },
      { name: 'Sole partenership', id: 1 },
      { name: 'Limited partenership', id: 2 },
      { name: 'General partenership', id: 3 },
      { name: 'Public Limited Compnay', id: 4 },
      { name: 'Private Limited Company', id: 5 },
    ];

    this.fundsFor = [
      { name: '' },
      { name: 'POS (Point Of Sale' },
      { name: 'Working Capital' },
      { name: 'Invoice financing' },
    ];

    this.loanTenureO = [
      { name: '' },
      { name: '3 Months', number: 3 },
      { name: '6 Months', number: 6 },
      { name: '9 Months', number: 9 },
      { name: '12 Months', number: 12 },
      { name: '16 Months', number: 16 },
      { name: '24 Months', number: 24 },
      { name: '36 Months', number: 36 },
    ];

    this.hearAboutUsO = [
      { name: '' },
      { name: 'Facebook' },
      { name: 'Instagram' },
      { name: 'Email' },
      { name: 'A Friend' },
      { name: 'Website' },
    ];

    this.checkIfUserLoggedIn();
    this._isUserAuthenticated();

    this.formGroup = new FormGroup({
      businessName: new FormControl<string | null>(null, Validators.required),
      businessRegistrationDate: new FormControl(null, Validators.required),
      businessNationalId: new FormControl(null, Validators.required),
      commercialRegistrationNumber: new FormControl('', Validators.required),
      businessType: new FormControl(null, Validators.required),
      cAddress: new FormControl(null, Validators.required),
      cPhone: new FormControl(null, Validators.required),
      website: new FormControl(null),

      fname: new FormControl(null, Validators.required),
      sname: new FormControl(null, Validators.required),
      tname: new FormControl(null, Validators.required),
      lname: new FormControl(null, Validators.required),
      email: new FormControl(this.email),
      phoneNumber: new FormControl(this.phone),
      idPass: new FormControl(null, Validators.required),
      loanAmount: new FormControl(null, Validators.required),
      loanTenure: new FormControl(null, Validators.required),
      loanReason: new FormControl(null, Validators.required),
      hearAboutUs: new FormControl(null, Validators.required),
      fCheck: new FormControl<string | null>(null),
      sCheck: new FormControl(null, Validators.required),
      tCheck: new FormControl(null, Validators.required),
      certificateOfRegistrationFile: new FormControl<string | null>(
        null,
        // Validators.required
      ),
      occupationalLicenseFile: new FormControl<string | null>(
        null,
        Validators.required
      ),
      companyRentContractsFile: new FormControl<string | null>(
        null,
        Validators.required
      ),
      financialStatementsFile: new FormControl<string | null>(
        null,
        Validators.required
      ),
    });
  }

  openAuthDialog(activeIndex: number): void {
    this.authDialogVisible = true;
    this.activeIndex = activeIndex;
    this._changeDetectorRef.markForCheck();
  }

  checkIfUserLoggedIn() {
    if (!localStorage.getItem('accessToken')) {
      this.openAuthDialog(1);
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this._unsubscribeAll);
  }

  private _isUserAuthenticated(): void {
    this._authService
      .isAccessAllowed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
        this._changeDetectorRef.markForCheck();
      });
  }

  userInfo (){
    const authData = localStorage.getItem('auth_data');
    if (authData) {
        try {
            const parsedAuthData = JSON.parse(authData);
            const eamil = parsedAuthData.email;
            const phoneNumber = parsedAuthData.regMobileNumber
            this.email = eamil;
            this.phone = phoneNumber
            
        } catch (error) {
            console.error('Error parsing auth_data from localStorage:', error);
        }
    }
  }

  submit() {
    // @ts-ignore
    if (this.formGroup.get('certificateOfRegistrationFile').invalid) {
      // @ts-ignore
      this.formGroup.get('certificateOfRegistrationFile').markAsTouched();

      return;
    }

    const authData: any = localStorage.getItem('auth_data');
    const parsedData = JSON.parse(authData);

    const body = {
      regCode: 400,
      cmpCode: environment.companyInfoCode,
      xapUserId: parsedData.id,
      brnCode: 1,
      langCode: 2,

      businessName: this.formGroup.value.businessName,
      businessRegistrationD: this.formGroup.value.businessRegistrationDate,
      businessNationalId: this.formGroup.value.businessNationalId,
      commercialRegistrationNumber:
        this.formGroup.value.commercialRegistrationNumber,
      businessType: this.formGroup.value.businessType,
      address: this.formGroup.value.cAddress,

      cPhone: this.formGroup.value.cAddress,
      website: this.formGroup.value.cAddress,

      firstName: this.formGroup.value.fname,
      secondName: this.formGroup.value.sname,
      thirdName: this.formGroup.value.tname,
      lastName: this.formGroup.value.lname,
      email: this.formGroup.value.email,
      phoneNumber: this.formGroup.value.phoneNumber,
      passportIdNumber: this.formGroup.value.idPass,

      loanAmount: this.formGroup.value.loanAmount,
      loanTenure: this.formGroup.value.loanTenure,
      loanReason: this.formGroup.value.loanReason,
      hearAboutUs: this.formGroup.value.hearAboutUs,

      certificateOfRegistrationFile:
        this.formGroup.value.certificateOfRegistrationFile,
      occupationalLicenseFile: this.formGroup.value.occupationalLicenseFile,
      companyRentContractsFile: this.formGroup.value.companyRentContractsFile,
      financialStatementsFile: this.formGroup.value.financialStatementsFile,
    };

    const headers = {
      Authorization: `Bearer ${parsedData.token}`,
    };

    return this._httpClient
      .post<any>(`${environment.serverOrigin}borrower_registration`, body, {
        headers,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response) => {
          console.log(response);
          this.registrationsSuccess = true;
          this._authService.getCustomerInfo(parsedData.id).subscribe();
          this.checkProfile(parsedData.id).subscribe(
            (response) => {
              console.log(response);

              localStorage.setItem('profileType', JSON.stringify(response));
            },
            (error) => {
              console.error('Error checking profile:', error);
            }
          );
          this._changeDetectorRef.detectChanges();
        },
        (error) => {
          console.error('Error in getCountries:', error);
        }
      );
  }

  onCertificateOfRegistrationFileSelect(event: any) {
    const file = event.files[0];
    if (file) {
      this.selectedFileName = file.name;

      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result as ArrayBuffer;

        this._httpClient
          .post(
            `${environment.platformServerOrigin}v1/_filestore`,
            fileContent,
            {
              headers: {
                'Content-Type': file.type,
              },
            }
          )
          .subscribe((response: any) => {
            console.log(response.result.id);
            this.formGroup
              .get('certificateOfRegistrationFile')
              ?.setValue(response.result.id);
          });
      };

      reader.readAsArrayBuffer(file);
    }
  }

  onOcupationalLicenseFileSelect(event: any) {
    const file = event.files[0];
    if (file) {
      this.selectedFileName = file.name;

      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result as ArrayBuffer;

        this._httpClient
          .post(
            `${environment.platformServerOrigin}v1/_filestore`,
            fileContent,
            {
              headers: {
                'Content-Type': file.type,
              },
            }
          )
          .subscribe((response: any) => {
            console.log(response.result.id);
            this.formGroup
              .get('occupationalLicenseFile')
              ?.setValue(response.result.id);
          });
      };

      reader.readAsArrayBuffer(file);
    }
  }

  onCompanyRentContractsFileSelect(event: any) {
    const file = event.files[0];
    if (file) {
      this.selectedFileName = file.name;

      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result as ArrayBuffer;

        this._httpClient
          .post(
            `${environment.platformServerOrigin}v1/_filestore`,
            fileContent,
            {
              headers: {
                'Content-Type': file.type,
              },
            }
          )
          .subscribe((response: any) => {
            console.log(response.result.id);
            this.formGroup
              .get('companyRentContractsFile')
              ?.setValue(response.result.id);
          });
      };

      reader.readAsArrayBuffer(file);
    }
  }

  onFinancialStatementsFileSelect(event: any) {
    const file = event.files[0];
    if (file) {
      this.selectedFileName = file.name;

      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result as ArrayBuffer;

        this._httpClient
          .post(
            `${environment.platformServerOrigin}v1/_filestore`,
            fileContent,
            {
              headers: {
                'Content-Type': file.type,
              },
            }
          )
          .subscribe((response: any) => {
            console.log(response.result.id);
            this.formGroup
              .get('financialStatementsFile')
              ?.setValue(response.result.id);
          });
      };

      reader.readAsArrayBuffer(file);
    }
  }

  next() {
    if (this.activeStep === 1) {
      Object.keys(this.formGroup.controls).forEach((controlName) => {
        const control = this.formGroup.get(controlName);
        control?.markAsTouched();
      });

      const controls = [
        'businessName',
        'businessRegistrationDate',
        'businessNationalId',
        'commercialRegistrationNumber',
        'businessType',
        'cAddress',
        'cPhone',
        'website',
      ];

      const allFieldsValid = controls.every((controlName) => {
        const control = this.formGroup.get(controlName);
        if (control?.invalid) {
          return false;
        }
        return true;
      });
      if (!allFieldsValid) {
        return;
      }

      this.activeStep = 2;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    if (this.activeStep < 4) {
      this.activeStep++;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  back() {
    if (this.activeStep !== 1) {
      this.activeStep--;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToPortal() {
    this._navigate.navigate(['borrower/borrower-portal']);
  }

  submitIfUserHaveProfile() {
    // @ts-ignore
    if (this.formGroup.get('certificateOfRegistrationFile').invalid) {
      // @ts-ignore
      this.formGroup.get('certificateOfRegistrationFile').markAsTouched();

      return;
    }

    const authData: any = localStorage.getItem('auth_data');
    const authDat2: any = localStorage.getItem('customer_data');
    const parsedData = JSON.parse(authData);
    const parsedData2 = JSON.parse(authDat2);

    const body = {
      regCode: parsedData2.regCode,
      cmpCode: parsedData2.cmpCode,
      brnCode: parsedData2.brnCode,
      custIdNo: parsedData2.custIdNo,
      langCode: 'en',

      xapUserId: parsedData.id,
      businessName: this.formGroup.value.businessName,
      businessRegistrationD: this.formGroup.value.businessRegistrationDate,
      businessNationalId: this.formGroup.value.businessNationalId,
      commercialRegistrationNumber:
        this.formGroup.value.commercialRegistrationNumber,
      businessType: this.formGroup.value.businessType,
      address: this.formGroup.value.cAddress,

      cPhone: this.formGroup.value.cAddress,
      website: this.formGroup.value.cAddress,

      firstName: this.formGroup.value.fname,
      secondName: this.formGroup.value.sname,
      thirdName: this.formGroup.value.tname,
      lastName: this.formGroup.value.lname,
      email: this.formGroup.value.email,
      phoneNumber: this.formGroup.value.phoneNumber,
      passportIdNumber: this.formGroup.value.idPass,

      loanAmount: this.formGroup.value.loanAmount,
      loanTenure: this.formGroup.value.loanTenure,
      loanReason: this.formGroup.value.loanReason,
      hearAboutUs: this.formGroup.value.hearAboutUs,

      certificateOfRegistrationFile:
        this.formGroup.value.certificateOfRegistrationFile,
      occupationalLicenseFile: this.formGroup.value.occupationalLicenseFile,
      companyRentContractsFile: this.formGroup.value.companyRentContractsFile,
      financialStatementsFile: this.formGroup.value.financialStatementsFile,
    };

    const headers = {
      Authorization: `Bearer ${parsedData.token}`,
    };

    return this._httpClient
      .put<any>(`${environment.serverOrigin}Customer/updateType`, body, {
        headers,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response) => {
          console.log(response);
          this.registrationsSuccess = true;
          this._authService.getCustomerInfo(parsedData.id).subscribe();
          this.checkProfile(parsedData.id).subscribe(
            (response) => {
              console.log(response);

              localStorage.setItem('profileType', JSON.stringify(response));
            },
            (error) => {
              console.error('Error checking profile:', error);
            }
          );
          this._changeDetectorRef.detectChanges();
        },
        (error) => {
          console.error('Error in getCountries:', error);
        }
      );
  }




  checkProfile(xapUserId: string): Observable<any> {
    return this._httpClient
      .post<any>(`${environment.serverOrigin}checkProfile`, {
        langCode: 'en',
        xapUserId: xapUserId,
      })
      .pipe(
        catchError((error) => {
          console.error('API call failed:', error);
          return of({ investor: false, borrower: false });
        })
      );
  }


  checkIfUserAlreadyHaveBorrowerAccount():void{
const data:any = localStorage.getItem('profileType');
const parsedData = JSON.parse(data);

if(parsedData.borrower){
  this._navigate.navigate(['borrower/borrower-portal']);
}else{
  console.log("ok");
  
}

  }
}
