import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CarouselModule } from 'primeng/carousel';

@Component({
  selector: 'app-mainslider',
  standalone: true,
  templateUrl: './mainslider.component.html',
  styleUrls: ['./mainslider.component.scss'],
  imports: [CarouselModule],
})
export class MainsliderComponent implements OnInit {
  mainSlider: any[];

  constructor() {}

  ngOnInit() {

    this.mainSlider = [
      {image:'../../../assets/images/slider/tower1.jpg'},
      {image:'../../../assets/images/slider/tower2.jpg'},
      {image:'../../../assets/images/slider/tower3.jpg'},
      {image:'../../../assets/images/slider/tower4.jpg'},
      {image:'../../../assets/images/slider/tower5.jpg'},
    ]
  }


}
