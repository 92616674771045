<div class="footer px-4 py-8 md:px-6 lg:px-8">

    <div class="grid grid-nogutter text-center lg:text-left">
        <div class="col-12 sm:col-6 md:col-4 lg:col-3 mt-5 flex flex-column align-items-center lg:align-items-start">
            <img src="assets/images/crowdxLogo.png" width="50%" height="100px" class="" alt="crowdx" style="object-fit: contain;">
            <div class="col-fixed flex align-items-center flex-order-0 lg:flex-order-1 mt-3">
                <a tabindex="0" class="cursor-pointer">
                    <i class="pi pi-facebook surface-900 p-1 text-sm border-circle text-0"></i>
                </a>
                <a tabindex="0" class="cursor-pointer mx-3">
                    <i class="pi pi-twitter surface-900 p-1 text-sm border-circle text-0"></i>
                </a>
                <a tabindex="0" class="cursor-pointer mx-3">
                    <i class="pi pi-youtube surface-900 p-1 text-sm border-circle text-0"></i>
                </a>
                <a tabindex="0" class="cursor-pointer">
                    <i class="pi pi-google surface-900 p-1 text-sm border-circle text-0"></i>
                </a>
            </div>

        </div>
        <div class="col-12 sm:col-6 md:col-4 lg:col-3 mt-3 flex flex-column" style="position: relative;">
            <span class="text-xl block text-dark-light font-semibold">
            
                {{'Navigation' | translate}}
            
            </span>
            <ul class="list-none p-0">
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light">
                    {{'Invest' | translate}}

                </a>
                </li>
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light"> 
                    {{'Borrow' | translate}}
                </a>
                </li>
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light">
                    {{'Learn' | translate}}

                </a>
                </li>
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light">
                    {{'About' | translate}}
                </a>
                </li>
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light">
                    {{'Contact Us' | translate}}</a></li>
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light" [routerLink]="['/faqs']">{{'FAQs' | translate}}</a>
                </li>
            </ul>
        </div>
        <div class="col-12 sm:col-6 md:col-4 lg:col-3 mt-3 flex flex-column" style="position: relative;">
            <span class="text-xl mb-3  block text-dark-light font-semibold" >
                {{'Give us a Call' | translate}}
            </span>
            <ul class="list-none p-0">
                <li class="mb-3">
                    <span tabindex="0" class="cursor-pointer mt-3 font-normal text-base text-gray-light"> {{'For Borrowers' | translate}} </span> 
                    <a class="cursor-pointer mt-3 font-normal text-base text-gray-light no-underline" href="tel:+96265525529">+96265525529</a> 
                </li>
                <li><span tabindex="0" class="cursor-pointer mt-3 font-normal text-base text-gray-light">{{'For Investors' | translate}}
                    </span> <a class="cursor-pointer mt-3 font-normal text-base text-gray-light no-underline"
                        href="tel:+96265525529">+962 65525529</a> </li>
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light">{{'Office Hours' | translate}} </a></li>
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light">{{'dayWork' | translate}}</a></li>
            </ul>
        </div>
        <div class="col-12 sm:col-6 md:col-4 lg:col-3 mt-3 flex flex-column"  style="position: relative;">
            <span class="text-xl mb-3 block text-dark-light font-semibold">
                {{'Terms & Conditions' | translate}}
            </span>
            <ul class="list-none p-0">
                <li><a tabindex="0" class="cursor-pointer mt-3 block font-normal text-base text-gray-light"> {{'Terms of Service' | translate}}</a>
                </li>
                <li><a tabindex="0" class=" cursor-pointer mt-3 block font-normal text-base text-gray-light">{{'Privacy Policy' | translate}}</a>
                </li>
                <li><a tabindex="0" class=" cursor-pointer mt-3 block font-normal text-base text-gray-light"> {{'Investor Agreement' | translate}}</a>
                </li>
                <li><a tabindex="0" class=" cursor-pointer mt-3 block font-normal text-base text-gray-light"> {{'Risks' | translate}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
