<div class="flex flex-column justify-content-start align-items-start">
  <div>
    <h1 style="font-weight: 700; font-size: 20px; line-height: 26px; color:#10194E ;">Company Details</h1>
    <p [style]="{'color': '#425375', 'font-size': '16px', 'font-weight': '300', 'line-height': '1.5'}">
      Where you are either a director of the business you derive your income from or you own > 5% of the equity, we are required to understand the business in greater detail.
    </p>
  </div>

  <div class="card flex justify-content-start mt-4">
    <div class="flex flex-column">
      <span [style]="{'font-size':'16px', 'font-weight':'400', 'color':'#425375'}">Please specify the countries that your company operates in:</span>
      <div class="mt-2">
        <p-multiSelect [options]="cities" [(ngModel)]="selectedCities" optionLabel="name" [style]="{'width':'80%'}"></p-multiSelect>
        <p-button label="Add" (click)="onAddButtonClick()" [style]="{'background-color':'#B4C1DB', 'color':'white', 'border-radius':'5px', 'border':'none','margin-left':'2%'}"></p-button>
      </div>

      <span class="mt-4" [style]="{'font-size':'16px', 'font-weight':'400', 'color':'#425375'}">At least one country must be added</span>
      <input class="mt-2" id="disabled-input" type="text" pInputText [disabled]="true" [(ngModel)]="countriesString" />
    </div>
  </div>

  <span class="mt-4" [style]="{'font-size':'16px', 'font-weight':'400', 'color':'#425375'}">Please tick the boxes below if any of the following questions are true about your business:</span>


  <div class="flex align-items-start justify-content-center gap-2 mt-4">
    <div>
      <p-checkbox [binary]="true" inputId="binary"></p-checkbox>
    </div>
    <div>
      <p [style]="{'color':'#425375', 'font-size':'16px', 'font-weight':'400', 'margin':'0'}">
        Is your company engaged in a cash-type business? This includes money services, gambling, high value goods dealings, real state agencies, pawn-broking, auctions/antiquities, oil/gas trading, car/boat/plane trading, used automobile/truck dealings and/or machine parts manufacture.
      </p>
    </div>
  </div>

  <hr style="color: #B4C1DB; border: .8px solid #B4C1DB; width: 100%;margin-top: 2%; margin-bottom: 2%;" />

  <div class="flex align-items-start justify-content-center gap-2 mt-2">
    <div>
      <p-checkbox [binary]="true" inputId="binary"></p-checkbox>
    </div>
    <div>
      <p [style]="{'color':'#425375', 'font-size':'16px', 'font-weight':'400', 'margin':'0'}">
        Is your company engaged in charitable/culture associations, NGOs, and/or religious bodies?
      </p>
    </div>
  </div>

  <hr style="color: #B4C1DB; border: .8px solid #B4C1DB; width: 100%;margin-top: 2%; margin-bottom: 2%;" />

  <div class="flex align-items-start justify-content-center gap-2 mt-2">
    <div>
      <p-checkbox [binary]="true" inputId="binary"></p-checkbox>
    </div>
    <div>
      <p [style]="{'color':'#425375', 'font-size':'16px', 'font-weight':'400', 'margin':'0'}">
        Is your company part of a group structure?
      </p>
    </div>
  </div>

  <hr style="color: #B4C1DB; border: .8px solid #B4C1DB; width: 100%;margin-top: 2%; margin-bottom: 2%;" />

  <div class="flex align-items-start justify-content-center gap-2 mt-2">
    <div>
      <p-checkbox [binary]="true" inputId="binary"></p-checkbox>
    </div>
    <div>
      <p [style]="{'color':'#425375', 'font-size':'16px', 'font-weight':'400', 'margin':'0'}">
        Is your company involved in any of the following activities: personal investments vehicles, shell bank/SPV, companies that have. nominee shareholders or directors or shares in bearer form, discretionary trusts, charitable trusts and/or waqfs.

      </p>
    </div>
  </div>

</div>


<div class="flex gap-4" [style]="{'margin':'auto'}">
  <button type="submit"  pButton pRipple label="Next" class="registerBtnSubmit" [routerLink]="['/invest-registration-step-three']"></button>
 </div>
