import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { CardModule } from 'primeng/card';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AuthDialogComponent } from '../auth-dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-gate',
  standalone: true,
  imports: [PrimeNgModule, CardModule, CommonModule, AuthDialogComponent, ProgressSpinnerModule],
  templateUrl: './gate.component.html',
  styleUrls: ['./gate.component.scss'],
})
export class GateComponent implements OnInit {
  userType: { investor: boolean, borrower: boolean } | null = null;
  loading: boolean = false;

  constructor(
    private _router: Router,
    private _httpClient: HttpClient,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const authData: string | null = localStorage.getItem('auth_data');
    if (authData) {
      this.loading = true;
      const parsedUserData = JSON.parse(authData);
      this.checkProfile(parsedUserData.id).subscribe(
        (response) => {
          console.log(response);
          this.userType = response;
          localStorage.setItem('profileType', JSON.stringify(response));
          this.loading = false;
          this.cdr.detectChanges();
        },
        (error) => {
          console.error('Error checking profile:', error);
          this.loading = false;
        }
      );
    } else {
      console.error('No auth data found in local storage.');
    }
  }

  onNavigateToInvestor() {
    this._router.navigate(['p2p']);
  }

  onNavigateToCreateInvestor() {
    this._router.navigate(['/invest/invest-registration']);
  }

  onNavigateToBorrower() {
    this._router.navigate(['/borrower/borrower-portal']);
  }

  onNavigateToCreateBorrower() {
    this._router.navigate(['/borrower/borrow-registration']);
  }

  checkProfile(xapUserId: string): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.serverOrigin}checkProfile`,
      {
        langCode: 'en',
        xapUserId: xapUserId,
      }
    ).pipe(
      catchError((error) => {
        console.error('API call failed:', error);
        return of({ investor: false, borrower: false });
      })
    );
  }
}
