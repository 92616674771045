<div>
  <p-dialog [styleClass]="'authDialog'" [showHeader]="false" (onHide)="closeDialog()" [(visible)]="visible" [style]="{width: '35vw'}" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="true" [resizable]="true">

    <p-tabView [styleClass]="'authTabView'" [activeIndex]="activeIndex" cache="false" dynamic="true">
      <p-button *ngIf="!isSignUpSuccess" (onClick)="closeDialog()" class="close-btn" icon="pi pi-times" [rounded]="true" [text]="true"></p-button>

      <ng-container *ngIf="showAuthTabs">
        <p-tabPanel *ngIf="!isSignUpSuccess" header="{{ 'sign-up' | translate }}">
          <app-auth-sign-up (onNavigateToSignIn)="navigateToSignIn($event)"></app-auth-sign-up>
        </p-tabPanel>

        <p-tabPanel *ngIf="!isSignUpSuccess" header="{{ 'login' | translate }}">
          <app-auth-login (onNavigateToPortal)="navigateToPortal($event)"></app-auth-login>
        </p-tabPanel>
      </ng-container>

      <p-tabPanel *ngIf="isSignUpSuccess" header="{{ 'Verification' | translate }}">
        <app-otp (onOTPSuccess)="updateRoute($event)"></app-otp>
      </p-tabPanel>

      <p-tabPanel *ngIf="isLoginSuccess || !showAuthTabs" header="{{ 'choosePortal' | translate }}">
        <app-gate (onNavigateToPortal)="updateLoginRoute($event)"></app-gate>
      </p-tabPanel>
    </p-tabView>
  </p-dialog>
</div>


