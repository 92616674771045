<div style="margin-left: 4%;">
  <div>
    <h1>New Borrower Registration</h1>
    <span>Eligibility Checks</span>
  </div>

  <br>

  <span style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px; margin-top: 2%;">
    Do you have a company bank account in good standing for at least 6 months.
  </span>

  <br>
  <br>

  <div style="display: flex;gap: 1%">
    <input type="radio" id="html" name="firstq" value="yes" style="border: 0.8 solid #90A0C1;">
    <label for="html">Yes</label>

    <input type="radio" id="css" name="firstq" value="no">
    <label for="css">No</label>
  </div>



  <p style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px; margin-top: 2%;">
    My business reflects commercial deposits of 20,000 JOD in the last 6 months
  </p>




  <div style="display: flex;gap: 1%">
    <input type="radio" id="html" name="secondq" value="yes" style="border: 0.8 solid #90A0C1;">
    <label for="html">Yes</label>

    <input type="radio" id="css" name="secondq" value="no">
    <label for="css">No</label>
  </div>


  <p style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px; margin-top: 2%;">
    I authorize Mada to perform background checks on me and my business.
  </p>




  <div style="display: flex;gap: 1%">
    <input type="radio" id="html" name="thirdq" value="yes" style="border: 0.8 solid #90A0C1;">
    <label for="html">Yes</label>

    <input type="radio" id="css" name="thirdq" value="no">
    <label for="css">No</label>
  </div>
</div>

<div class="flex gap-2" style="margin-left: 20%; margin-top: 3%;">
  <button style="color: #10194E; background-color: transparent; border-radius: 6px; font-size: 16px; line-height: 24px; text-align: center; font-weight: 500; width: 134px; height: 50px; cursor: pointer;" [routerLink]="['/borrow-registration-step-two']">back</button>
  <button style="color: white; background-color: #16BE81; border-radius: 6px; font-size: 16px; line-height: 24px; text-align: center; font-weight: 500; width: 134px; height: 50px; border: none; cursor: pointer;" [routerLink]="['/borrow-registration-phone-confirm']">Next</button>
</div>
