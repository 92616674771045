import { Component, OnInit , ChangeDetectorRef } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { DownloadappComponent } from 'src/app/shared/downloadapp/downloadapp.component';
import { MainsliderComponent } from 'src/app/shared/mainslider/mainslider.component';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { AuthService } from 'src/app/core/auth';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-borrow',
  standalone: true,
  imports: [PrimeNgModule,CarouselModule,DownloadappComponent,MainsliderComponent, TranslateModule],
  templateUrl: './borrow.component.html',

  styleUrl: './borrow.component.scss'
})
export class BorrowComponent implements OnInit {
  responsiveOptions: any[] | undefined;
  isAuthenticated: boolean;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,

    private _authService: AuthService,
  ){}

  ngOnInit() {
    this._isUserAuthenticated();
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }


  private _isUserAuthenticated(): void {
    this._authService
      .isAccessAllowed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
        this._changeDetectorRef.markForCheck();
      });
  }
}
