import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { CarouselModule } from 'primeng/carousel';
import { DownloadappComponent } from 'src/app/shared/downloadapp/downloadapp.component';
import { MainsliderComponent } from 'src/app/shared/mainslider/mainslider.component';

@Component({
  selector: 'app-team-finance',
  standalone: true,
  imports: [PrimeNgModule,CommonModule, PrimeNgModule, TranslateModule, FormsModule,CarouselModule,DownloadappComponent,MainsliderComponent, ReactiveFormsModule],
  templateUrl: './team-finance.component.html',
  styleUrl: './team-finance.component.scss'
})
export class TeamFinanceComponent implements OnInit {

  form: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder, private translate: TranslateService) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, this.phoneNumberValidator()]],  
      companyName: ['', Validators.required],
      revenueSize: ['', Validators.required],
      fundsFor: ['', Validators.required],
    });
  }

  ngOnInit(): void {
   
    this.initializeFormControls();
  }

  initializeFormControls() {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, this.phoneNumberValidator()]],  
      companyName: ['', Validators.required],
      revenueSize: ['', Validators.required],
      fundsFor: ['', Validators.required],
    });
  }

  submitForm() {
    this.submitted = true;
    if (this.form.valid) {
      console.log(this.form.value);
    } else {
      this.markFormGroupTouched(this.form);
    }
  }

 
   
  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phoneNumberRegex = /^[0-9]*$/;  
      const valid = phoneNumberRegex.test(control.value);
      return valid ? null : { invalidPhoneNumber: { value: control.value } };
    };
  }

  
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

}