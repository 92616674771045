import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { SignUpComponent } from '../sign-up';
import { LoginComponent } from '../login';
import { TranslateModule } from '@ngx-translate/core';
import { OtpComponent } from '../otp/otp.component';
import { Router } from '@angular/router';
import { GateComponent } from '../gate/gate.component';

@Component({
  selector: 'app-auth-dialog',
  standalone: true,
  imports: [CommonModule, PrimeNgModule, SignUpComponent, LoginComponent, TranslateModule, OtpComponent, GateComponent],
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthDialogComponent {
  @Input() visible: boolean = false;
  @Input() activeIndex: number | null;

  @Output() onCloseDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onUpdateActiveIndex: EventEmitter<null> = new EventEmitter<null>();

  isSignUpSuccess: boolean = false;
  isLoginSuccess: boolean = false;
  showAuthTabs: boolean = true;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  navigateToSignIn(event: boolean): void {
    if (event === true) {
      this.isSignUpSuccess = true;
    }
  }

  navigateToPortal(event: boolean): void {
    if (event === true) {
      this.isLoginSuccess = true;
      this.showAuthTabs = false;
      this.activeIndex = 2;
    }
  }

  updateRoute(event: boolean): void {
    if (event === true) {
      this.isSignUpSuccess = false;
      this.activeIndex = 1;
    }
  }

  updateLoginRoute(event: boolean): void {
    if (event === true) {
      this.isLoginSuccess = true;
      this.activeIndex = 2;
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.activeIndex = null;
    this.onCloseDialog.emit(false);
    this._changeDetectorRef.markForCheck();
  }

  handleSignUpSuccess(): void {
    this.isSignUpSuccess = true;
  }

  isInvestRegistrationRoute(): boolean {
    return this.router.url === '/invest-registration';
  }
}
