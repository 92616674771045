import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { ImageModule } from 'primeng/image';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { TagModule } from 'primeng/tag';
import { ProgressBarModule } from 'primeng/progressbar';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { DownloadappComponent } from 'src/app/shared/downloadapp/downloadapp.component';
import { MainsliderComponent } from 'src/app/shared/mainslider/mainslider.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-invest-new',
  standalone: true,
  imports: [CommonModule, PrimeNgModule, ImageModule,ButtonModule,CarouselModule,TagModule,ProgressBarModule,SliderModule,FormsModule,DownloadappComponent,MainsliderComponent,TranslateModule],
  templateUrl: './invest-new.component.html',
  styleUrl: './invest-new.component.scss'
})



export class InvestNewComponent implements OnInit{
  products: any[];
  responsiveOptions: any[] | undefined;
  loanValue: number = 1000;
  interestValue: number = 14;
  months: number = 1;
  monthlyPayment: number = 0;
  totalRepayment: number = 0;
  ngOnInit() {
    this.products = [
      {
        paragraph: 'Seeking funds or eager to invest? CrowdX delivers!',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCx71l7CXI7IGdq3gjz_pjW1SCQ-l_O9BNemRhrbB7CQ&s',
        inventoryStatus: 'In Stock',
      },
      {
        paragraph:
          'Interested in maximizing your returns? CrowdX offers exclusive investment avenues!',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxnfdQVUrOIGAx1PIrRmclGkCbIQcv7-qxs4zaSHe4LA&s',
        inventoryStatus: 'Out of Stock',
      },
      {
        paragraph: 'Need capital to grow your business? CrowdX is the key!',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS155Lhuga9pZ0KLBv6zPU_oxscWC0DF8ARDZ-TNgoRxA&s',
        inventoryStatus: 'Low Stock',
      },
      {
        paragraph: 'Product 1',
        image: '../../../assets/images/about/Coins.png',
        inventoryStatus: 'In Stock',
      },
      {
        paragraph: 'Product 2',
        image: '../../../assets/images/about/Coins.png',
        inventoryStatus: 'Out of Stock',
      },
      {
        paragraph: 'Product 3',
        image: '../../../assets/images/about/Coins.png',
        inventoryStatus: 'Low Stock',
      },
    ];
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ];

  }

  increaseMony() {
    if (this.loanValue < 10000) {
      this.loanValue += 100;
    }
    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }

  decreaseMony() {
    if (this.loanValue > 1000) {
      this.loanValue -= 100;
    }
    this.calculateTotalRepayment();
  }

  increaseInterest() {
    if (this.interestValue < 18) {
      this.interestValue++;
    }
    this.calculateTotalRepayment();
  }

  decreaseInterest() {
    if (this.interestValue > 11) {
      this.interestValue--;
    }
    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }

  increaseMonth() {
    switch (this.months) {
      case 1:
        this.months = 3;
        break;
      case 3:
        this.months = 6;
        break;
      case 6:
        this.months = 9;
        break;
      case 9:
        this.months = 12;
        break;
      case 12:
        this.months = 18;
        break;
      default:
        break;
    }

    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }

  decreaseMonth() {
    switch (this.months) {
      case 3:
        this.months = 1;
        break;
      case 6:
        this.months = 3;
        break;
      case 9:
        this.months = 6;
        break;
      case 12:
        this.months = 9;
        break;
      case 18:
        this.months = 12;
        break;
      default:
        break;
    }
    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }

  calculateTotalRepayment() {
    const totalInterest =
      (this.loanValue * this.interestValue * (this.months / 12)) / 100;

    this.totalRepayment = Math.round(this.loanValue + totalInterest);
  }

  calculateMonthlyPayment() {
    const monthlyInterestRate = this.interestValue / 100 / 12;
    const numberOfPayments = this.months;

    const numerator =
      this.loanValue *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, numberOfPayments);
    const denominator = Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1;
    this.monthlyPayment = numerator / denominator;

    this.monthlyPayment = Math.round(this.monthlyPayment * 100) / 100;
  }
}

