import { Component } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';

@Component({
  selector: 'app-borrow-registration-step-three',
  standalone: true,
  imports: [RadioButtonModule,PrimeNgModule],
  templateUrl: './borrow-registration-step-three.component.html',
  styleUrl: './borrow-registration-step-three.component.scss'
})
export class BorrowRegistrationStepThreeComponent {

}
