import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNgModule } from './shared/modules/prime-ng';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './core/language';
import { NetworkService } from './core/network/network.service'
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, CommonModule, PrimeNgModule]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'crowd-lending';
  private networkStatusSubscription: Subscription;

  constructor(
    private _translateService: TranslateService,
    @Inject(DOCUMENT) private _document: Document,
    private _languageService: LanguageService,
    private _networkService: NetworkService,
    private _messageService: MessageService
  ) {
    const activeLang = this._languageService.activeLang?.name ? this._languageService.activeLang?.name : 'en';
    this._setActiveLang(activeLang);
  }

  ngOnInit() {
    this.networkStatusSubscription = this._networkService.getNetworkStatus().subscribe(isOnline => {
      if (isOnline) {
        this._messageService.add({ severity: 'success', summary: 'Network Status', detail: 'You are back online!' });
      } else {
        this._messageService.add({ severity: 'error', summary: 'Network Status', detail: 'You are offline!' });
      }
    });
  }

  ngOnDestroy() {
    if (this.networkStatusSubscription) {
      this.networkStatusSubscription.unsubscribe();
    }
  }

  _setActiveLang(lang: string): void {
    this._translateService.use(lang);
    this.setLayoutDirection(lang);
  }

  setLayoutDirection(lang: string): void {
    let htmlTag = this._document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
  }
}
