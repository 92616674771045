<div>
    <div class="flex flex-column lg:flex-row justify-content-center align-items-center gap-7">
        <div class="text-center lg:text-right">
            <div class="mt-6 mb-3 font-bold text-6xl text-900">{{'something-went-wrong' | translate}}</div>
            <p class="text-700 text-3xl mt-0 mb-6">{{'try-again-later' | translate}}</p>
            <button [routerLink]="['/']" pButton type="button" label="{{
                'back-to-home-page' | translate
            }}" class="p-button-outlined"></button>
        </div>
        <div>
            <img src="assets/images/feedback/404-rocket.png" alt="Image" class="w-full md:w-28rem">
        </div>
    </div>
</div>