// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`. 
export const environment = {
  // baseUrl: 'http://185.27.119.36:8484',
  // serverOrigin: 'http://185.27.119.36:8484/v1/bankGate/',
  // platformServerOrigin: 'http://185.27.119.36:8484/',



  baseUrl: 'https://api.madakjo.com',
  serverOrigin: 'https://api.madakjo.com/v1/bankGate/',
  platformServerOrigin: 'https://api.madakjo.com/',
  production: false,
  cmpCode: 'crowdx',
  companyInfoCode: 1
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
