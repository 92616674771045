import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';

interface Day {
  name: string;
}

interface Months {
  name: string;
}

interface Years {
  name: string;
}

@Component({
  selector: 'app-borrow-registration-step-two',
  standalone: true,
  imports: [DropdownModule, InputTextModule, PrimeNgModule],
  templateUrl: './borrow-registration-step-two.component.html',
  styleUrls: ['./borrow-registration-step-two.component.scss'],
})
export class BorrowRegistrationStepTwoComponent implements OnInit {
  registrationForm: FormGroup;
  days: Day[] | undefined;
  months: Months[] | undefined;
  years: Years[] | undefined;
  businessNationalId: any;
  businessName: string;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      day: [1, Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required],
      businessNationalId: ['', Validators.required],
      businessName:['', Validators.required]
    });

    this.businessNationalId = localStorage.getItem('national_id');

    if (this.businessNationalId) {
      this.registrationForm.patchValue({ businessNationalId: this.businessNationalId });
    }


    this.days = Array.from({ length: 31 }, (_, index) => ({ name: `${index + 1}` }));
    this.months = [
      { name: 'January' },
      { name: 'February' },
      { name: 'March' },
      { name: 'April' },
      { name: 'May' },
      { name: 'June' },
      { name: 'July' },
      { name: 'August' },
      { name: 'September' },
      { name: 'October' },
      { name: 'November' },
      { name: 'December' },
    ];
    this.years = Array.from({ length: 100 }, (_, index) => ({ name: `${new Date().getFullYear() - index}` }));
  }
}
