import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Invest, InvestData } from '../../invets.types';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { InvestConfirmComponent } from '../../confirm';
import { InvestFormComponent } from '../../form';
import { TranslateModule } from '@ngx-translate/core';
import { InvestDialogComponent } from '../../dialog/dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invest-list-card',
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgModule,
    InvestFormComponent,
    InvestConfirmComponent,
    TranslateModule,
    InvestDialogComponent
  ],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestListCardComponent implements OnInit {

  isInvestFormVisibile: boolean = false;
  isConfirmInvestVisibile: boolean = false;

  investData: InvestData;
  randomCoverImages: string[] = [
    'assets/images/avatar/blog/p2p1.jpg',
    'assets/images/avatar/blog/p2p2.jpg',
    'assets/images/avatar/blog/p2p3.jpg',
    'assets/images/avatar/blog/p2p4.jpg',
    'assets/images/avatar/blog/p2p5.jpg',
  ];
  @Input() invest: Invest;
  coverImageUrl: string = '';
  avatarImageUrl: string = '';

  ngOnInit(): void {
     this.fetchCoverImage();
     this.fetchAvatarImage();

  }


     constructor(private router: Router) { }



  /**
   * On Invest
   * @param {InvestData}investData
   */
  onInvest(investData: InvestData): void {
    this.openConfirmInvestDialog(investData);
  }

  /**
  * Open Invest Form Dialog
  * @param {Invest} invest
  */

     handleInvestmentClick(invest: any) {
        if (invest.sector === "Real state") {
            localStorage.setItem('real_state_item', this.getRandomCoverImage())
            this.navigateToInvestment(invest);
        } else {
            this.navigateToInvestmentToinvestmentsTwo(invest);
        }
    }

  openInvestFormDialog(invest: Invest): void {
    this.invest = invest;
    this.isInvestFormVisibile = true;
    console.log(invest);

  }
   navigateToInvestment(invest: any) {
        const sectorId = invest.sectorId;
        const prodKey = invest.prodKey;

        this.router.navigateByUrl(`invest/PropertyInvestRealEComponent/${sectorId}/${prodKey}`);
    }
    navigateToInvestmentToinvestmentsTwo(invest: any) {
      const sectorId = invest.sectorId;
      const prodKey = invest.sysPkText;
console.log(invest);

      this.router.navigateByUrl(`invest/${sectorId}/${prodKey}`);
    }
  /**
   * Close Invest Form Dialog
   * @param {boolean}isClose
   */
  closeInvestFormDialog(isClose: boolean): void {
    this.isInvestFormVisibile = isClose;
  }


  /**
   * Open Confirm Invest Dialog
   * @param {InvestData}investData
   */
  openConfirmInvestDialog(investData: InvestData): void {
    this.investData = investData;
    this.isConfirmInvestVisibile = true;
  }

  /**
   * Close Confirm Invest Dialog
   * @param {boolean}isClose
   */
  closeConfirmInvestDialog(isClose: boolean): void {
    this.isConfirmInvestVisibile = isClose;
  }

  getRandomCoverImage(): string {
    const randomIndex = Math.floor(Math.random() * this.randomCoverImages.length);
    return this.randomCoverImages[randomIndex];
  }

  fetchCoverImage(): void {
    const coverImageAttachment = this.invest.loanAttachmentsDto.find((attachment:any) => attachment.fileType === 1);
    if (coverImageAttachment) {

      const imageUrl = `http://20.216.29.47:8210/v1/_xfilestore/crowdx/${coverImageAttachment.fullPath}`;
      this.coverImageUrl = imageUrl;
    }
  }

  fetchAvatarImage(): void {
    const avatarImageAttachment = this.invest.loanAttachmentsDto.find((attachment:any) => attachment.fileType === 2);
    if (avatarImageAttachment) {



      const imageUrl = `http://20.216.29.47:8210/v1/_xfilestore/crowdx/${avatarImageAttachment.fullPath}`;
      console.log(imageUrl);

      this.avatarImageUrl = imageUrl; // corrected the variable name to avatarImageUrl
    }
  }
}
