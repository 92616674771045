<h1 style="text-align: center; color: #10194E; font-size: 36px; line-height: 54px; font-weight: 700;">Registration Process</h1>


<div style="background-color: white; padding: 4%;">

  <h1 style="color: #10194E; font-size: 28px; font-weight: 700; line-height: 54px;">Introduction</h1>
  <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px;">At CrowdX, we are committed to make the investor registration process as easy and straightforward as possible. We take our investor take-on procedures very seriously and as part of this we are required to collect and maintain valid identity/information of our investors. This helps us know and understand you better as part of our KYC (Know Your Customer) requirements which are in accordance with the guidelines issued by the Central Bank Of Jordan and we have to understand who you are, your source of funds and your previous dealings.</p>

  <h1 style="color: #10194E; font-size: 28px; font-weight: 700; line-height: 54px;">Registration Process</h1>


  <h1 style="color: #10194E; font-size: 22px; font-weight: 700; line-height: 54px;">Registration Process</h1>

  <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px; margin-top: 2%;">At CrowdX, we are committed to make the investor registration process as easy and straightforward as possible. We take our investor take-on procedures very seriously and as part of this we are required to collect and maintain valid identity/information of our investors. This helps us know and understand you better as part of our KYC (Know Your Customer) requirements which are in accordance with the guidelines issued by the Central Bank Of Jordan and we have to understand who you are, your source of funds and your previous dealings.</p>

  <ol style="margin: 0; padding: 0; margin-top: 2%;">
    <li style="color: #425375; font-size: 16px; font-weight: 300; line-height: 26px;">The first step of our registration process requires basic personal information in order to register on our platform which includes name, date of birth, nationality, country of residence and contact number.</li>
    <li style="color: #425375; font-size: 16px; font-weight: 300; line-height: 26px;">At this stage we also request our investors to create a user name and password which would be used to access the investor platform to progress in the registration process and browse through our investor platform. The user name is entirely up to you however we request it is a minimum of six letters.</li>
    <li style="color: #425375; font-size: 16px; font-weight: 300; line-height: 26px;">The password must be 8-16 characters and contain upper and lower case letters, numbers and a special character e.g. $, %, !</li>
  </ol>

  <h1 style="color: #10194E; font-size: 22px; font-weight: 700; line-height: 54px;">Completing the registration</h1>
  <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px; margin-top: 2%;">
    To gain full access to the platform and add funds to your account, you will need to complete a few more steps as part of our investor take-on process.
    As part of this we will require the following:
  </p>
  <ol style="margin: 0; padding: 0; margin-top: 2%;">
    <li style="color: #425375; font-size: 16px; font-weight: 300; line-height: 26px;">The first step of our registration process requires basic personal information in order to register on our platform which includes name, date of birth, nationality, country of residence and contact number.</li>
    <li style="color: #425375; font-size: 16px; font-weight: 300; line-height: 26px;">At this stage we also request our investors to create a user name and password which would be used to access the investor platform to progress in the registration process and browse through our investor platform. The user name is entirely up to you however we request it is a minimum of six letters.</li>
    <li style="color: #425375; font-size: 16px; font-weight: 300; line-height: 26px;">The password must be 8-16 characters and contain upper and lower case letters, numbers and a special character e.g. $, %, !</li>
  </ol>
  <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px; margin-top: 2%;">
    At this stage our team would also request identification verification (your passport and proof of your residential address/Jordanian ID) and a selfie holding your passport to comply with our investor take-on requirements. Please note that additional documents may also be requested from time to time.
  </p>


  <h1 style="color: #10194E; font-size: 22px; font-weight: 700; line-height: 54px;">Funding your account</h1>
  <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px; margin-top: 2%;">
    Once you have registered as explained above and have agreed to our Terms and Conditions and Privacy Policy we complete our KYC checks as a final step. After this is done, you are able to add funds into your CrowdX account and you can then start to make bids on our Marketplace or purchase existing finance parts on the transfer facility.
    </p>

  <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px; margin-top: 2%;">
    You are only able to transfer funds to CrowdX from your nominated bank account (as indicated during the registration process – this is a requirement as per international Anti-money laundering standards). We will therefore only accept transfers initiated through internet banking, telephone banking or visiting a bank branch. To ensure the funds get credited to your account without delay, please also let us know the amount you are transferring via the “Add funds” page on the platform. Please allow 1-3 business days for funds to be processed and credited to your account. All funds credited will be net of any bank fees.
  </p>

  <h1 style="color: #10194E; font-size: 22px; font-weight: 700; line-height: 54px;">Funding your account</h1>
  <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px; margin-top: 2%;">
    You funds are safely held in CrowdX P2P Limiteds segregated Client Monies Account therefore, in the unlikely event of CrowdX P2P Limited ceasing to trade, all outstanding finance re-payments will continue to be managed and unwound to completion, ensuring that re-payments continue to be distributed to investors.
  </p>
    <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px; margin-top: 2%;">
      CrowdX P2P Limiteds Client Monies Account is held in the UAE with Emirates NBD.Our legal advisors, Support Legal drafted the legal documentation related to the platform, including all terms & conditions and the loan contracts between individual investors and businesses. All Documentation is drafted under DIFC Law.
    </p>

  <h1 style="color: #10194E; font-size: 22px; font-weight: 700; line-height: 54px;">Protection of your details</h1>
  <p style="color:#425375; font-size: 16px; font-weight: 300; line-height: 26px; margin-top: 2%;">
    We will only share your details or documents with relevant government authorities (if requested to do so).We do not share your details or documents with any other external or third parties without your authorization.
  </p>
</div>
