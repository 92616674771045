<app-mainslider></app-mainslider>

<h1 class="text-center" style="margin-top: 6%;">
  {{'CrowdX Calculator' | translate}}
</h1>
<h3 class="text-center" style="font-weight: 300;">
  {{'What are funds for' | translate}} {{'?' | translate}}
</h3>
<h5 style="font-weight: 300; text-align: center;">
  {{'The purpose of a loan calculator can be summarized in four steps:' | translate}}
</h5>

<div class="MainCoverAllBusiness">

  <div class="coverAllBusiness">
    <div class="insideCoverAllBusiness">
      <h1>{{'1' | translate}}</h1>
      <h4>
        {{'Estimation' | translate}}
      </h4>
      <p>
        {{'Quickly estimate monthly payments and total repayment amounts based on loan parameters.' | translate}}
      </p>
    </div>
  </div>
  <div class="line">-</div>

  <div class="coverAllBusiness">
    <div class="insideCoverAllBusiness">
      <h1>{{'2' | translate}}</h1>
      <h4>
        {{'Planning' | translate}}
      </h4>
      <p>
        {{'Plan finances by adjusting parameters to see how they affect payments, aiding in decision-making.' | translate}}
      </p>
    </div>

  </div>
  <div class="line">-</div>

  <div class="coverAllBusiness">
    <div class="insideCoverAllBusiness">
      <h1>{{'3' | translate}}</h1>
      <h4>
        {{'Comparison' | translate}}
      </h4>
      <p>
        {{'Easily compare loan options from different lenders to find the most suitable terms.' | translate}}
      </p>
    </div>
  </div>
  <div class="line">-</div>
  <div class="coverAllBusiness">
    <div class="insideCoverAllBusiness">
      <h1>{{'4' | translate}}</h1>
      <h4>
        {{'Understanding' | translate}}
      </h4>
      <p>
        {{'Gain insight into the financial impact of borrowing, ensuring responsible decision-making.' | translate}}
      </p>
    </div>
  </div>
</div>


<div class="borroweCalculatorMain">

  <h2 class="text-center">{{'Borrower Financial Calculator' | translate}}</h2>
  <h4 class="text-center">{{'Put your plans into action!' | translate}}</h4>
  <div class="borroweCalculator">
    <div>

      <p class="mb-3" style="color: rgb(185, 185, 185); font-size: 12px;">Loan Amount (1000-10.0000)</p>
      <div class="flex justify-content-between align-items-center">
        <h2 class="mt-0">
          {{loanValue}} JD
        </h2>

        <div class="flex align-items-center gap-2">
          <button class="increasBtn" (click)="increaseMony()" [disabled]="loanValue === 100000">+</button>
          <button class="increasBtn" (click)="decreaseMony()" [disabled]="loanValue === 1000">-</button>
        </div>
      </div>

      <!-- <p-slider [(ngModel)]="loanValue"  (onChange)="onChangeSlider($event)" [min]="1000" [max]="10000" styleClass="w-full mt-3" [disabled]="false"</p-slider> -->
      <p-slider [(ngModel)]="loanValue" [min]="1000" [max]="10000" styleClass="w-full mt-3" [disabled]="false"
        (onChange)="onChangeSlider($event)">
      </p-slider>



      <p class="mt-6 mb-3" style="color: rgb(185, 185, 185); font-size: 12px;">Interest Rate</p>
      <div class="flex justify-content-between align-items-center">
        <h2 class="mt-0">
          {{getCeiledInterestValue()}}%
        </h2>

        <div class="flex align-items-center gap-2">
          <button class="increasBtn" (click)="increaseInterest()" [disabled]="interestValue === 36">+</button>
          <button class="increasBtn" (click)="decreaseInterest()" [disabled]="interestValue === 10">-</button>
        </div>
      </div>
      <p-slider (onChange)="onChangeRate($event)" [(ngModel)]="interestValue" [min]="10" [max]="18" styleClass="w-full mt-3"
        [disabled]="false"></p-slider>

      <p class="mt-6 mb-3" style="color: rgb(185, 185, 185); font-size: 12px;"> Length of Loan Period (6 - 36 Months)
      </p>
      <div class="flex justify-content-between align-items-center">
        <h2 class="mt-0">
          {{months}} Months
        </h2>

        <div class="flex align-items-center gap-2">
          <button class="increasBtn" (click)="increaseMonth()" [disabled]="months === 36">+</button>
          <button class="increasBtn" (click)="decreaseMonth()" [disabled]="months === 1">-</button>
        </div>
      </div>

      <p-slider (onChange)="onChangeMonth($event)" [(ngModel)]="months" [min]="6" [max]="36" styleClass="w-full mt-3"
        [disabled]="false"></p-slider>
    </div>

    <div class="flex justify-content-between mt-6">
      <div class="flex flex-column" style="background-color: #f7f7f7; padding: 8px; border-radius: 8px;width: 40%;">
        <p>Calculated at:</p>
        <p>{{interestValue}}% Yearly interest rate</p>
      </div>
      <div class="flex flex-column" style="background-color: #f7f7f7; padding: 8px; border-radius: 8px;width: 40%;">
        <p>Monthly:</p>
        <p>{{monthlyPayment}} JD</p>
      </div>
    </div>
    <div style="background-color: #f7f7f7;padding: 8px;border-radius: 8px;"
      class="flex align-items-center flex-column mt-6">
      <p>Total repayment:</p>
      <p>{{totalRepayment}} JD</p>
    </div>
  </div>

</div>


<app-downloadapp></app-downloadapp>