<app-auth-dialog [visible]="authDialogVisible" [activeIndex]="activeIndex"></app-auth-dialog>

<div [formGroup]="formGroup" class="w-8 m-auto mt-8 borrowerForm">

  <div class="flex justify-content-evenly regFormNav"
    style="border: 2px solid #41CE8E;background-color: #41CE8E; border-radius: 8px;">

    <h5 (click)="activeStep = 1" [ngClass]="{ 'activeTab': activeStep === 1, 'notActiveTab': activeStep !== 1 }">
      {{'Business Info' | translate}}
    </h5>

    <h5 [ngClass]="{ 'activeTab': activeStep === 2, 'notActiveTab': activeStep !== 2 }">
      {{'Contact Details' | translate}}
    </h5>

    <h5 [ngClass]="{ 'activeTab': activeStep === 3, 'notActiveTab': activeStep !== 3 }">
      {{'Loan Details' | translate}}
    </h5>

    <h5 [ngClass]="{ 'activeTab': activeStep === 4, 'notActiveTab': activeStep !== 4 }">
      {{'Attachments' | translate}}
    </h5>

  </div>


  <h4 class="kickstart">{{'KickstartRegistration' | translate}}|
    <span style="color: rgba(255, 167, 73)">{{'Step' | translate}} {{activeStep }} {{'of' | translate}} 4 </span>
  </h4>

  <!--Step One (1) Start-->
  <div class="flex justify-content-between formCol" *ngIf="activeStep === 1">
    <div class="flex flex-column gap-4 formColInsider" style="margin-top: 4%; width: 46%;">
      <div>
        <label for="businessName">{{'Company Name / Trading Name' | translate}}</label>
        <input type="text" id="businessName" formControlName="businessName" class="w-full custom-input" />
        <p-message severity="error"
          *ngIf="formGroup.get('businessName').invalid && formGroup.get('businessName').touched"
          text="{{'Company / Trading Name is required' | translate}}"></p-message>
      </div>

      <div>
        <label for="businessRegistrationDate">{{'Business Registration Date' | translate}}</label>
        <input type="date" id="businessRegistrationDate" formControlName="businessRegistrationDate"
          class="w-full custom-input">
        <p-message severity="error"
          *ngIf="formGroup.get('businessRegistrationDate').invalid && formGroup.get('businessRegistrationDate').touched"
          text="{{'Invalid Business Registration Date / Required' | translate}}"></p-message>
      </div>

      <div>
        <label for="businessNationalId">{{'Business National ID' | translate}}</label>
        <input type="number" id="businessNationalId" formControlName="businessNationalId" class="w-full"
          class="w-full custom-input">
        <p-message severity="error"
          *ngIf="formGroup.get('businessNationalId').invalid && formGroup.get('businessNationalId').touched"
          text="{{'Invalid Business National ID / Required' | translate}}"></p-message>
      </div>

      <div>
        <label for="commercialRegistrationNumber">{{'Commercial Registration Number' | translate}}</label>
        <input type="number" id="commercialRegistrationNumber" formControlName="commercialRegistrationNumber"
          class="w-full" class="w-full custom-input">
        <p-message severity="error"
          *ngIf="formGroup.get('commercialRegistrationNumber').invalid && formGroup.get('commercialRegistrationNumber').touched"
          text="{{'Invalid Commercial Registration Number / Required' | translate}}"></p-message>
      </div>

      <div>
        <label for="businessType">{{'Company Type' | translate}}</label>
        <select formControlName="businessType" class="custom-dropdown">
          <option value="" disabled selected>{{'Select Company Type' | translate}}</option>
          <option *ngFor="let type of companyType" [value]="type.id">
            {{ type.name }}

          </option>
        </select>

        <p-message severity="error"
          *ngIf="formGroup.get('businessType').invalid && formGroup.get('businessType').touched"
          text="{{'Company Type is required' | translate}}"></p-message>
      </div>
    </div>

    <div class="flex flex-column gap-4 formColInsider" style="margin-top: 4%; width: 46%;">
      <div>
        <label for="cAddress">{{'Address' | translate}}</label>
        <input type="text" id="cAddress" formControlName="cAddress" class="w-full custom-input">
        <p-message severity="error" *ngIf="formGroup.get('cAddress').invalid && formGroup.get('cAddress').touched"
          text="{{'Address is required' | translate}}"></p-message>
      </div>

      <div>
        <label for="cPhone">{{'Company Phone Number' | translate}}</label>
        <input type="text" id="cPhone" formControlName="cPhone" class="w-full custom-input">
        <p-message severity="error" *ngIf="formGroup.get('cPhone').invalid && formGroup.get('cPhone').touched"
          text="{{'Invalid Company Phone Number' | translate}}"></p-message>
      </div>

      <div>
        <label for="website">{{'Company Website' | translate}}</label>
        <input type="url" id="website" formControlName="website" class="w-full custom-input">
        <!-- <p-message severity="error" *ngIf="formGroup.get('website').invalid && formGroup.get('website').touched" text="Invalid Company Website"></p-message> -->
      </div>
    </div>
  </div>

  <!--Step One (1) End-->

  <!--Step Two (2) Start-->
  <div class="flex justify-content-between formCol" *ngIf="activeStep === 2">
    <div class="flex flex-column gap-4 formColInsider" style="margin-top: 4%; width: 46%;">


      <div>
        <label for="fname">{{'First Name' | translate}} </label>
        <input type="text" id="fname" formControlName="fname" class="w-full custom-input" />
      </div>



      <div>
        <label for="sname">{{'Second Name' | translate}}</label>
        <input type="text" id="sname" formControlName="sname" class="w-full custom-input">
      </div>


      <div>
        <label for="tname">{{'Third Name' | translate}}</label>
        <input type="text" id="tname" formControlName="tname" class="w-full custom-input">
      </div>

      <div>
        <label for="lname">{{'Last Name' | translate}} </label>
        <input type="text" id="lname" formControlName="lname" class="w-full custom-input">
      </div>
    </div>

    <div class="flex flex-column gap-4 formColInsider" style="margin-top: 4%; width: 46%;">

      <div>
        <label for="email">{{'email' | translate}}</label>
        <input type="email" id="email" formControlName="email" readonly class="w-full custom-input" />

      </div>

      <div>
        <label for="phoneNumber">{{'phone-number' | translate}}</label>
        <input type="tel" id="phoneNumber" formControlName="phoneNumber" readonly class="w-full custom-input" />
      </div>


      <div>
        <label for="idPass"> {{'ID/ Passport' | translate}}</label>
        <input type="text" id="idPass" formControlName="idPass" class="w-full custom-input" />
      </div>

    </div>
  </div>
  <!--Step Two (2) End-->


  <!--Step Two (3) Start-->
  <div class="flex justify-content-between formCol" *ngIf="activeStep === 3">
    <div class="flex flex-column gap-4 formColInsider" style="margin-top: 4%; width: 46%;">


      <div>
        <label for="amount">{{'Loan Amount (1,000 - 10,000)' | translate}}</label>
        <input type="number" id="amount" formControlName="loanAmount" class="w-full custom-input" />
      </div>



      <div>
        <label for="terms">{{'Loan Tenure' | translate}}</label>
        <select formControlName="loanTenure" class="custom-dropdown">
          <option value="" disabled selected>{{'Select Tenure' | translate}}</option>
          <option *ngFor="let tenure of loanTenureO" [value]="tenure.number">
            {{ tenure.name }}
          </option>
        </select>
      </div>



      <div>
        <label for="reason">{{'What do you need the funds for' | translate}} {{'?' | translate}} </label>
        <select formControlName="loanReason" class="custom-dropdown">
          <option value="" disabled selected>{{'Select Reason' | translate}}</option>
          <option *ngFor="let reason of fundsFor" [value]="reason.name">
            {{ reason.name }}
          </option>
        </select>
      </div>

      <div>
        <label for="postalCode">{{'How did you hear about us' | translate}}</label>
        <select formControlName="hearAboutUs" class="custom-dropdown">
          <option value="" disabled selected>{{'Select an option' | translate}}</option>
          <option *ngFor="let option of hearAboutUsO" [value]="option.name">
            {{ option.name }}
          </option>
        </select>

      </div>

    </div>

    <div class="flex flex-column gap-4 formColInsider" style="margin-top: 4%; width: 46%;">

      <div class="flex align-items-start">
        <input type="checkbox" id="fCheck" formControlName="fCheck" />
        <label for="fCheck">{{'Do you have a company bank account in good standing for at least 6 months' | translate}}{{'?' | translate}}</label>
      </div>

      <div class="flex align-items-start">
        <input type="checkbox" id="sCheck" formControlName="sCheck" />
        <label for="sCheck">{{'My business reflects commercial deposits of 20,000 JOD in the last 6 months' | translate}}</label>
      </div>

      <div class="flex align-items-start">
        <input type="checkbox" id="fCheck" formControlName="fCheck" />
        <label for="fCheck">{{'I authorize Mada to perform background checks on me and my business' | translate}}</label>
      </div>

    </div>
  </div>

  <!--Step Two (3) End-->


  <!--Step Two (4) Start-->
  <div class="flex flex-column w-full mt-6" *ngIf="activeStep === 4">

    <div class="gap-2 uploadFiles uploadContaniner">
      <p-fileUpload id="fileUpload" mode="basic" name="demo[]" chooseIcon="pi-paperclip" accept=".pdf"
        maxFileSize="100000000" chooseLabel="" (onSelect)="onCertificateOfRegistrationFileSelect($event)"
        [customUpload]="true">
      </p-fileUpload>
      <small>{{'Upload Company certificate of registration' | translate}} </small>
      <sup class="text-black">*

      </sup>
      <!-- <p-message severity="error" *ngIf="formGroup.get('certificateOfRegistrationFile').invalid && formGroup.get('certificateOfRegistrationFile').touched" text="Certificate of Registration file is required"></p-message> -->

    </div>

    <div class="uploadFiles uploadContaniner gap-2 mt-4">

      <p-fileUpload id="fileUpload" mode="basic" name="demo[]" chooseIcon="pi-paperclip" accept=".pdf"
        maxFileSize="100000000" (onSelect)="onOcupationalLicenseFileSelect($event)" [customUpload]="true">
      </p-fileUpload>
      <small>{{'Occupational license' | translate}}</small>
      <sup
      class="text-black">*

  </sup>
    </div>

    <div class="uploadFiles uploadContaniner gap-2 mt-4">
      <p-fileUpload id="fileUpload" mode="basic" name="demo[]" chooseIcon="pi-paperclip" accept=".pdf"
        maxFileSize="100000000" (onSelect)="onCompanyRentContractsFileSelect($event)" [customUpload]="true">
      </p-fileUpload>
      <small>{{'Company rent contracts' | translate}}</small>
      <sup
      class="text-black">*

  </sup>
    </div>
    <div class="uploadFiles uploadContaniner gap-2 mt-4">
      <p-fileUpload id="fileUpload" mode="basic" name="demo[]" chooseIcon="pi-paperclip" accept=".pdf"
        maxFileSize="100000000" (onSelect)="onFinancialStatementsFileSelect($event)" [customUpload]="true">
      </p-fileUpload>
      <small>{{'Bank statements' | translate}} </small>
      <sup
      class="text-black">*

  </sup>
    </div>
  </div>
  <!--Step Two (4) End-->




  <div class="nextAndBackBtn w-full mt-4">
    <button type="button" class="backBtn" (click)="back()">{{'Back' | translate}}</button>
    <button *ngIf="activeStep !== 4" type="button" class="nextBtn" (click)="next()">{{'Next' | translate}}</button>
    <button *ngIf="activeStep === 4" type="button" class="nextBtn"
      (click)="(submitUserType?.borrower === true || submitUserType?.investor === true) ? submitIfUserHaveProfile() : submit()">{{'Submit' | translate}}</button>

  </div>

</div>



<div>
  <p-dialog [(visible)]="registrationsSuccess" [styleClass]="'authDialog'" [showHeader]="false"
    [style]="{width: '20vw'}" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '90vw'}"
    [draggable]="true" [resizable]="true">

    <div class="flex justify-content-center align-items-center gap-2 p-2">
      <i class="pi pi-check-circle" style="color: #41CE8E; font-size: 24px;"></i>
      <h4>{{'Registration Success' | translate}}</h4>
    </div>
    <div class="flex justify-content-center align-items-center gap-2 p-2" style="margin-bottom: 3%; cursor: pointer;"
      (click)="navigateToPortal()">
      <small>{{'Continue to portal' | translate}}</small>
      <i class="pi pi-arrow-right"></i>
    </div>

  </p-dialog>
</div>