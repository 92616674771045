import { CUSTOMER_DATA, Customer } from "src/app/core/customer";
import { StorageService } from "src/app/core/helpers/storage";
import { InvestData } from "./invets.types";


export const doInvestData = (investData: InvestData, _storageService: StorageService) => {
  const userDataLocal: any = localStorage.getItem('customer_data');
  const userData = JSON.parse(userDataLocal);
  return {
      'regCode': userData.regCode,
      'cmpCode': userData.cmpCode,
      'brnCode': userData.brnCode,
      'userid': userData.custIdNo,
      'amount': investData.amount,
      'sysPkText': investData.sysPkText,
      'userName': investData.userName,
      'phoneNumber': investData.phoneNumber,
      "langCode":"en"
  }
}


export const amountCheckData = (amount: string, _storageService: StorageService) => {
    const customer: Customer | any = _storageService.getData(CUSTOMER_DATA);
    return {
        'regCode': customer.regCode,
        'cmpCode': customer.cmpCode,
        'userid': customer.custIdNo,
        "amount": amount
    }
}
