<div style="margin-left: 10%;">
<div>
  <h1 style="color: #10194E; font-size: 20px; font-weight: 700; line-height: 26px;">
    New Borrower Registration
  </h1>
  <h5 style="color: #10194E; font-size: 20px; font-weight: 400; line-height: 26px;">Business Info</h5>

  <span style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px;">Date of Company Establishment</span>

  <div class="flex" style="width: 34%; gap: 5px;">
    <p-dropdown class="registerDropDown" [options]="days" formControlName="day" optionLabel="name" placeholder="Day" [style]="{'width':'155px', 'margin-top':'2%'}"></p-dropdown>
    <p-dropdown class="registerDropDown" [options]="months" formControlName="month" optionLabel="name" placeholder="Month" [style]="{'width':'155px', 'margin-top':'2%'}"></p-dropdown>
    <p-dropdown class="registerDropDown" [options]="years" formControlName="year" optionLabel="name" placeholder="Year" [style]="{'width':'155px', 'margin-top':'2%'}"></p-dropdown>
</div>

<div class="flex flex-column align-items-start mt-4">
  <span style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px;">Business Name</span>
  <input type="text" pInputText formControlName="text" style="width: 480px; margin-top: 0%;"/>
</div>


<div class="flex flex-column align-items-start mt-4">
  <span style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px;">Business National ID</span>
  <input type="text" pInputText formControlName="text" [value]="businessNationalId" style="width: 480px; margin-top: 0%;" disabled/>
</div>


<div class="flex flex-column align-items-start mt-4">
  <span style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px;">Commercial Registration Number</span>
  <input type="text" pInputText formControlName="text" style="width: 480px; margin-top: 0%;"/>
</div>


<div class="flex flex-column align-items-start mt-4">
  <span style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px;">Company Type</span>
  <input type="text" pInputText formControlName="text" style="width: 480px; margin-top: 0%;"/>
</div>

<div class="flex gap-2" style="margin-left: 10%; margin-top: 3%;">
<button style="color: #10194E; background-color: transparent; border-radius: 6px; font-size: 16px; line-height: 24px; text-align: center; font-weight: 500; width: 134px; height: 50px; cursor: pointer;" [routerLink]="['/borrow-registration']">back</button>
<button style="color: white; background-color: #16BE81; border-radius: 6px; font-size: 16px; line-height: 24px; text-align: center; font-weight: 500; width: 134px; height: 50px; border: none; cursor: pointer;" [routerLink]="['/borrow-registration-step-three']">Next</button>
</div>
</div>


</div>
