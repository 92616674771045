import { inject } from "@angular/core";
import { CUSTOMER_DATA, Customer } from "src/app/core/customer";
import { StorageService } from "src/app/core/helpers/storage";
import { LanguageService } from "../language";
import { environment } from "src/environments/environment";

export const customerInfo = (_storageService: StorageService, _languageService: LanguageService) => {
    const customer: Customer | any = _storageService.getData(CUSTOMER_DATA);
    const activeLang = _languageService.activeLang?.name ? _languageService.activeLang?.name : 'en';
    return {
        'regCode': 400,
        'cmpCode': environment.companyInfoCode,
        'langCode': activeLang,
        'userid': customer?.custIdNo,
        'brnCode': customer?.brnCode
    }
}
