<app-mainslider></app-mainslider>

<h1 class="text-center mt-6">{{'How to invest' | translate}}{{'?' | translate}}</h1>
<p class="text-center howToInvestParagraph">{{'CrowdX is available to serve investors all over the world' | translate}}</p>

<div class="howToInvestGrid">
  <div class="howToInvestContainer">
    <small>{{'Registration & Verification' | translate}}</small>
    <p>{{'Investors sign up on CrowdX, providing details and undergoing verification for security and compliance.' | translate}}</p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Bank Details' | translate}}</small>
    <p>
      {{'IBANs are submitted for transactions, and CrowdX assigns wallets based on risk preferences and interests.' | translate}}
    </p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Projects Selection & Investment' | translate}}</small>
    <p>
      {{'Investors choose projects aligned with preferences and risk tolerance, deciding on investment amounts.' | translate}}
    </p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Monitoring & Updates' | translate}}</small>
    <p>
      {{'CrowdX provides a user-friendly interface for tracking project growth and investment progress, with regular milestone and financial updates.' | translate}}
    </p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Withdrawal & Risk Management' | translate}}</small>
    <p>
      {{'Investors can withdraw funds with transparent processes. CrowdX manages risks through project due diligence and financial monitoring.' | translate}}
    </p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Customer Assistance' | translate}}</small>
    <p>
      {{'Dedicated support is available throughout the investment journey to address investor queries or concerns.' | translate}}
    </p>
  </div>
</div>



<div class='header my-5'>
  <div>
    <div class='content'>
      <div>
        <h1 class="worksForInvestorTitle my-5" style="font-size: 40px;"> 
          <small style="font-size: 40px;" class="wonderingSmall"> {{'Wondering why you should Invest with Crowd' | translate}} <span style="color: #71DBAC; font-size: 40px;">{{'X' | translate}}</span>{{'?' | translate}}</small>
        </h1>
        <p>
        {{'ourCrowedX' | translate}} 
        </p>
        <button id="LmBtn" style="" [routerLink]="['/invest-registration']">{{'Learn more' | translate}}</button>
      </div>
      <div>
        <img src="../../../assets/images/thinking.png" id="imgInvest" />
      </div>
    </div>
  </div>
</div>


<h1 class="text-center mt-6">{{'CrowdX Calculator' | translate}}</h1>
<p class="text-center">{{'What are funds for' | translate}}{{'?' | translate}}</p>

<div class="howToInvestGrid">
  <div class="howToInvestContainer">
    <small>{{'Registration & Verification' | translate}}</small>
    <p>{{'Investors sign up on CrowdX, providing details and undergoing verification for security and compliance.' | translate}}</p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Bank Details' | translate}}</small>
    <p>
      {{'IBANs are submitted for transactions, and CrowdX assigns wallets based on risk preferences and interests.' | translate}}
    </p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Projects Selection & Investment' | translate}}</small>
    <p>
      {{'Investors choose projects aligned with preferences and risk tolerance, deciding on investment amounts.' | translate}}
    </p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Monitoring & Updates' | translate}}</small>
    <p>
      {{'CrowdX provides a user-friendly interface for tracking project growth and investment progress, with regular milestone and financial updates.' | translate}}
    </p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Withdrawal & Risk Management' | translate}}</small>
    <p>
      {{'Investors can withdraw funds with transparent processes. CrowdX manages risks through project due diligence and financial monitoring.' | translate}}
    </p>
  </div>

  <div class="howToInvestContainer">
    <small>{{'Customer Assistance' | translate}}</small>
    <p>
      {{'Dedicated support is available throughout the investment journey to address investor queries or concerns.' | translate}}
    </p>
  </div>
</div>




<div class="borroweCalculatorMain">

  <h2 class="text-center">{{'Borrower Financial Calculator' | translate}}</h2>
  <h4 class="text-center">{{'Put your plans into action!' | translate}}</h4>
  <div class="borroweCalculator">
    <div>

      <p class="mb-3" style="color: rgb(185, 185, 185); font-size: 12px;">Loan Amount (1000-10.000)</p>
      <div class="flex justify-content-between align-items-center">
        <h2 class="mt-0">
          {{loanValue}} JD
        </h2>

        <div class="flex align-items-center gap-2">
          <button class="increasBtn" (click)="increaseMony()" [disabled]="loanValue === 10000">+</button>
          <button class="increasBtn" (click)="decreaseMony()" [disabled]="loanValue === 1000">-</button>
        </div>
      </div>

      <p-slider [(ngModel)]="loanValue" [min]="1000" [max]="10000" styleClass="w-full mt-3"></p-slider>
      <p class="mt-6 mb-3" style="color: rgb(185, 185, 185); font-size: 12px;">Interest Rate</p>
      <div class="flex justify-content-between align-items-center">
        <h2 class="mt-0">
          {{interestValue}}%
        </h2>

        <div class="flex align-items-center gap-2">
          <button class="increasBtn" (click)="increaseInterest()" [disabled]="interestValue === 18">+</button>
          <button class="increasBtn" (click)="decreaseInterest()" [disabled]="interestValue === 11">-</button>
        </div>
      </div>
      <p-slider [(ngModel)]="interestValue" [min]="11" [max]="18" styleClass="w-full mt-3"></p-slider>

      <p class="mt-6 mb-3" style="color: rgb(185, 185, 185); font-size: 12px;"> Length of Loan Period (6 - 18 Months) </p>
      <div class="flex justify-content-between align-items-center">
        <h2 class="mt-0">
          {{months}} Months
        </h2>

        <div class="flex align-items-center gap-2">
          <button class="increasBtn" (click)="increaseMonth()" [disabled]="months === 18">+</button>
          <button class="increasBtn" (click)="decreaseMonth()" [disabled]="months === 1">-</button>
        </div>
      </div>

      <p-slider [(ngModel)]="months" [min]="1" [max]="18" styleClass="w-full mt-3"></p-slider>
    </div>

    <div class="flex justify-content-between mt-6">
      <div class="flex flex-column" style="background-color: #f7f7f7; padding: 8px; border-radius: 8px;width: 40%;">
        <p>Calculated at:</p>
        <p>{{interestValue}}% Yearly interest rate</p>
      </div>
      <div class="flex flex-column" style="background-color: #f7f7f7; padding: 8px; border-radius: 8px;width: 40%;">
        <p>Monthly:</p>
        <p>{{monthlyPayment}} JD</p>
      </div>
    </div>
    <div style="background-color: #f7f7f7;padding: 8px;border-radius: 8px;" class="flex align-items-center flex-column mt-6">
      <p>Total repayment:</p>
      <p>{{totalRepayment}} JD</p>
    </div>
  </div>

</div>



<app-downloadapp></app-downloadapp>



