export const transactionList = [
    {
        id: 1,
        title: 'EVARO Shopping Mall',
        subtitle: 'Account Name Reference',
        totalInvestment: 5000,
        investmentDuration: 14,
        investmentRemaining: 6,
        investmentGrade: 'High',
        sectorName: 'Real State',
        sectorId: 1,
        roi: 20,
        logo: 'assets/images/avatar/blog/blog-1.png',
        coverPhoto: 'assets/images/blog/blog-2.png',
        description: 'Your shopping mall investment is delivering as promised, generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow.',
        transactions: [
            {
                id: 1,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'Paid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 2,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Sun, Sep 3, 2023, 6:45 PM',
                status: 'unPaid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png'
            },
            {
                id: 3,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Fri, Sep 2, 2023, 6:45 PM',
                status: 'Paid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 4,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Thu, Sep 1, 2023, 6:45 PM',
                status: 'unPaid',
                roi: 0.00,
                logo: 'assets/images/avatar/blog/blog-1.png',
            }
        ]
    },
    {
        id: 2,
        title: 'EVARO Shopping Mall',
        subtitle: 'Account Name Reference',
        totalInvestment: 5000,
        investmentDuration: 14,
        investmentRemaining: 6,
        investmentGrade: 'High',
        sectorName: 'Real State',
        sectorId: 1,
        roi: 20,
        logo: 'assets/images/avatar/blog/blog-1.png',
        coverPhoto: 'assets/images/blog/blog-2.png',
        description: 'Your shopping mall investment is delivering as promised, generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow.',
        transactions: [
            {
                id: 1,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'Paid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 2,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'unPaid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 3,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'Paid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 4,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'unPaid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            }
        ]
    },
    {
        id: 3,
        title: 'EVARO Shopping Mall',
        subtitle: 'Account Name Reference',
        totalInvestment: 5000,
        investmentDuration: 14,
        investmentRemaining: 6,
        investmentGrade: 'High',
        sectorName: 'Real State',
        sectorId: 1,
        roi: 20,
        logo: 'assets/images/avatar/blog/blog-1.png',
        coverPhoto: 'assets/images/blog/blog-2.png',
        description: 'Your shopping mall investment is delivering as promised, generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow.',
        transactions: [
            {
                id: 1,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'Paid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 2,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'unPaid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 3,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'Paid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 4,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'unPaid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            }
        ]
    },
    {
        id: 4,
        title: 'EVARO Shopping Mall',
        subtitle: 'Account Name Reference',
        totalInvestment: 5000,
        investmentDuration: 14,
        investmentRemaining: 6,
        investmentGrade: 'High',
        sectorName: 'Real State',
        sectorId: 1,
        roi: 20,
        logo: 'assets/images/avatar/blog/blog-1.png',
        coverPhoto: 'assets/images/blog/blog-2.png',
        description: 'Your shopping mall investment is delivering as promised, generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow.',
        transactions: [
            {
                id: 1,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'Paid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 2,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'unPaid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 3,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'Paid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            },
            {
                id: 4,
                title: 'EVARO Shopping Mall',
                amount: 23.2,
                date: 'Mon, Sep 4, 2023, 6:45 PM',
                status: 'unPaid',
                roi: 15.2,
                logo: 'assets/images/avatar/blog/blog-1.png',
            }
        ]
    }
]