import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-downloadapp',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './downloadapp.component.html',
  styleUrl: './downloadapp.component.scss'
})
export class DownloadappComponent {

}
