<!-- <div class="p-4 md:p-6 lg:p-8 bg-ghost-White -->
<div class="p-4 md:p-6 lg:p-8 bg-ghost-White">
    <div class="bg-ghost-White" style="min-height: 20rem" [ngStyle]="{'margin-left': isAuthenticated ? '0%' : '0%'}">
        <router-outlet></router-outlet>
    </div>
</div>

<!-- <div class="border-2 bg-ghost-White border-dashed surface-border border-round" style="min-height: 20rem">
        <router-outlet></router-outlet>
    </div> -->
