import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'primeng/carousel';
import { DownloadappComponent } from 'src/app/shared/downloadapp/downloadapp.component';
import { MainsliderComponent } from 'src/app/shared/mainslider/mainslider.component';
@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CarouselModule,DownloadappComponent,MainsliderComponent, TranslateModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent  {

}
