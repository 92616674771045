<div class='header' id="downloadAppSec">
  <div>
    <div class='content' id="contentApp">
      <div>
        <h1>
          {{'readyToGet' | translate}} <br />
          {{'started?' | translate}}

        </h1>
        <p>
          {{'ItOnlyTakes3minutesToDownload' | translate}}<br />
          {{'ourAppSignUpInvestAndBorrow' | translate}}
        </p>

        <div class="flex image-conitner">
          <img src="../../../assets/images/about/googleplay.webp" class="downloadStore" />
          <img src="../../../assets/images/about/appstore.webp" class="downloadStore" />
        </div>
        <img src="../../../assets/images/about/qrcode.png" class="w-3 h-2 mt-3" />
      </div>
      <div>

        <img src="../../../assets/images/about/iphone.png" class="downloadAppPhone" />

      </div>
    </div>

  </div>
</div>


<!--
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
-->

<div style="background-color: #fcfcfc; padding-top: 4%;padding-bottom: 4%;margin-top: 4%;">

  <div class="verifiedBox">
    <div class="w-5 verifiedBox2">
      <h1>
        {{'verified-businesses&' | translate}} <br /> {{'privacy-protection' | translate}}
      </h1>
      <p style="font-size: 20px; font-weight: 300;" class="verifiedBox2p">
        {{'makeEasyInvestments' | translate}}

      </p>
    </div>

    <div class="flex flex-column w-5 verifiedBox3">


      <div class="flex align-items-center gap-2">
        <img src="../../../assets/images/about/fingerprint.png" style="height: 50px;" />
        <div>
          <h3 class="m-0">
            {{'data-encryption' | translate}}
          </h3>
          <p class="m-0" style="font-weight: 300; line-height: 1.4;">
            {{'data-encryption2' | translate}}
          </p>
        </div>
      </div>


      <div class="flex align-items-center gap-2 mt-4">
        <img src="../../../assets/images/about/verified.png" style="height: 50px;" />
        <div>
          <h3 class="m-0">
            {{'verified-businesses' | translate}}

          </h3>
          <p class="m-0" style="font-weight: 300; line-height: 1.4;">
            {{'verified-businesses2' | translate}}

          </p>
        </div>
      </div>


    </div>
  </div>

</div>