// borrow-registration-confirm-phone.component.ts

// Import necessary modules and components
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';

@Component({
  selector: 'app-borrow-registration-confirm-phone',
  standalone: true,
  imports: [CommonModule, FormsModule, PrimeNgModule],
  templateUrl: './borrow-registration-confirm-phone.component.html',

  styleUrls: ['./borrow-registration-confirm-phone.component.scss'],
})
export class BorrowRegistrationConfirmPhoneComponent {
  otpDigits: string[] = ['', '', '', '', '', ''];

  // Handle the input change event for OTP digits
  onOtpInputChange(index: number) {
    if (this.otpDigits[index] !== '') {
      if (index < 5) {
        this.focusNextInput(index);
      }
    } else {
      if (index > 0) {
        this.focusPreviousInput(index);
      }
    }
  }

  focusNextInput(index: number) {
    const nextInput = document.getElementsByName(
      `otp${index + 1}`
    )[0] as HTMLInputElement;
    nextInput.focus();
  }

  focusPreviousInput(index: number) {
    const previousInput = document.getElementsByName(
      `otp${index - 1}`
    )[0] as HTMLInputElement;
    previousInput.focus();
  }

  verifyOtp() {
    const enteredOtp = this.otpDigits.join('');

    console.log('Entered OTP:', enteredOtp);

    console.log('Verification logic executed');
  }

  isOtpEmpty(): boolean {
    return (
      this.otpDigits.some((digit) => digit === '') ||
      this.otpDigits.length !== 6
    );
  }
}
