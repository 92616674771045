import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../common/navbar';
import { ContentComponent } from '../common/content';
import { FooterComponent } from '../common/footer';

import { Router } from '@angular/router';

@Component({
  selector: 'app-horizontal-layout',
  standalone: true,
  imports: [CommonModule, NavbarComponent, ContentComponent, FooterComponent],
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})
export class HorizontalLayoutComponent implements OnInit {

  currentRoute: string | any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.currentRoute = this.router.url

  }

}
