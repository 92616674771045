<app-mainslider></app-mainslider>

<div class="aboutSlider" style="margin-top: -8%; direction: ltr;">
  <div class="card">
    <p-carousel [value]="products" [numVisible]="3" [numScroll]="3" [circular]="false"
      [responsiveOptions]="responsiveOptions" [showIndicators]="false">
      <ng-template let-product pTemplate="item"> 
        <div class="aboutSliderInside"
          style="margin: auto;height: 250px; width: 78%; padding: 4%; background-color: #fcfcfc; display: flex; flex-direction: row; border-radius: 10px; position: relative;">
          <div class="flex flex-column justify-content-between">
            <p class="mb-1 w-8 font-light">
              {{ product.paragraph | translate }}
            </p>
            <img src="../../../assets/images/logo/futeric.svg" class="cxLogoSlider" />
          </div>
          <img [src]="product.image" [alt]="product.name" class="image"
            style="width: 50%;height: 50%; object-fit: contain;  position: absolute; bottom: 2%; right: 0;" />
        </div>
      </ng-template>
    </p-carousel>
  </div>
</div>




<div class='header my-5'>
  <div>
    <div class='content'>
      <div>
        <h1>{{'About Crowd' | translate}}<span style="color: #41CE8E;">{{'X' | translate}}</span><br><span style="font-size: 20px;font-weight: 400;">
           {{'learn-more-about-crowdx' | translate }}</span></h1>
        <p>
          {{'learn-more-about-crowdx-paragraph' | translate }}
        </p>


        <h2 id="h2Btn" class="text-center text-white">
          {{'LearnaboutCrowd' | translate }}
          <span style="color: #41CE8E;">{{'X' | translate}}</span>
        </h2>

      </div>
      <div>
        <img class=""
          src="https://images.pexels.com/photos/210600/pexels-photo-210600.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="image not found " />

      </div>
    </div>

  </div>
</div>

<h1 id="h1Main" class="text-center worksForInvestorTitle my-5">
 {{'How Crowdx Works for Investors' | translate}} </h1>

<p id="pMain" class="text-center worksForInvestorParaGraph my-5" style="font-size: 20px;">
  {{'CrowdX is available to serve investors all over the world' | translate}}
</p>


<div class="how-it-work-cards my-5">

  <div class="how-it-works ">
    <h3>{{'1' | translate}}</h3>
    <p id="titleP"> {{'ExploreInvestmentOpportunities' | translate }}</p>
    <p> {{'ExploreInvestmentOpportunities2' | translate }}</p>
  </div>

  <div class="how-it-works">
    <h3>{{'2' | translate}}</h3>
    <p id="titleP">{{'ReviewProjectDetails' | translate}}</p>
    <p>{{'ReviewProjectDetailsP' | translate}}</p>
  </div>

  <div class="how-it-works">
    <h3>{{'3' | translate}}</h3>
    <p id="titleP">{{'MakeInvestments' | translate}}</p>
    <p>{{'MakeInvestmentsP' | translate}}</p>
  </div>

  <div class="how-it-works">
    <h3>{{'4' | translate}}</h3>
    <p id="titleP">{{'TrackProgress' | translate}}</p>
    <p>{{'TrackProgressP' | translate}}</p>
  </div>

  <div class="how-it-works">
    <h3>{{'5' | translate}}</h3>
    <p id="titleP"> {{'EarnReturns' | translate}}</p>
    <p>
      {{'EarnReturnsParagraph' | translate}}
    </p>
  </div>


  <div class="how-it-works">
    <h3>{{'6' | translate}}</h3>
    <p id="titleP">{{'SupportInnovationandGrowth' | translate}}</p>
    <p>{{'SupportInnovationandGrowthP' | translate}}</p>

  </div>
</div>


<div class='header my-5'>
  <div>
    <div class='content'>
      <div>

        <h1 class="worksForInvestorTitle my-5" style="font-size: 40px;">{{'Crowd' | translate}}<span style="color: #41CE8E;">{{'X' | translate}}</span>
        </h1>
        <p>{{'ourCrowedX' | translate}}</p>
      </div>
      <div>
        <img src="../../../assets/images/invest.jpg" />

      </div>
    </div>

  </div>
</div>


<!--
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
-->
<!-- <h1 class="text-center worksForInvestorTitle  my-5" style="font-size: 40px;margin-top: 8%;">How Crowd<span
    style="color: #41CE8E;">X</span> Works for Borrowers</h1> -->



    <h1 class="text-center worksForInvestorTitle  my-5" style="font-size: 40px;margin-top: 8%;"> {{'How CrowdX Works for Borrowers' | translate}}</h1>
<p class="text-center worksForInvestorParaGraph  my-5" style="font-size: 20px;">{{'CrowdXWorld' | translate}}</p>


<div class="how-it-work-cards  my-5">

  <div class="how-it-works">
    <h3>{{'1' | translate}}</h3>
    <p id="titleP">{{'RegistrationandApplication' | translate}}</p>
    <p>{{'RegistrationandApplicationP' | translate}}</p>
  </div>

  <div class="how-it-works">
    <h3>{{'2' | translate}}</h3>
    <p id="titleP">{{'CreditAssessmentandRiskCategorization' | translate}}</p>
    <p>{{'CreditAssessmentandRiskCategorizationP' | translate}}</p>
  </div>

  <div class="how-it-works">
    <h3>{{'3' | translate}}</h3>
    <p id="titleP">{{'LoanListingandFunding' | translate}}</p>
    <p>{{'LoanListingandFundingP' | translate}}</p>
  </div>

  <div class="how-it-works">
    <h3>{{'4' | translate}}</h3>
    <p id="titleP">{{'LoanApprovalandDisbursement' | translate}}</p>
    <p>{{'LoanApprovalandDisbursementP' | translate}}</p>
  </div>

  <div class="how-it-works">
    <h3>{{'5' | translate}}</h3>
    <p id="titleP">{{'Repayment' | translate}}</p>
    <p>{{'RepaymentP' | translate}}</p>
  </div>


  <div class="how-it-works">
    <h3>{{'6' | translate}}</h3>
    <p id="titleP">{{'CommunicationandSupport' | translate}}</p>
    <p>{{'CommunicationandSupportP' | translate}}</p>
  </div>
</div>


<app-downloadapp></app-downloadapp>