 <app-mainslider></app-mainslider>


<div class="my-5">
  <h1 class="text-center">
    {{'How does it Work' | translate}}{{'?' | translate}}
  </h1>
  <p class="text-center" style="font-weight: 400; font-size: 18px;">
    {{'CrowdX is your safest plattform - see WHY' | translate}}
  </p>
</div>





<div class="borrowerImages">
  <div class="flex flex-column justify-content-center align-items-center">
    <img src="../../../assets/images/borrower/applyonline.avif" class="borrowerImage" />
    <img src="../../../assets/images/borrower/green_arrow.png" class="greenArrow" />
    <h4 id="h4" style="font-weight: 300;">
      {{'Flexible & easy' | translate}} <br />
      {{'to apply online' | translate}}
    </h4>
  </div>

  <div class="flex flex-column justify-content-center align-items-center">
    <img src="../../../assets/images/borrower/sandclock.jpeg" class="borrowerImage" />
    <img src="../../../assets/images/borrower/green_arrow.png" class="greenArrow" />
    <h4 id="h4" style="font-weight: 300;">
      {{'Flexible & easy' | translate}} <br />
      {{'to apply online' | translate}}
    </h4>
  </div>

  <div class="flex flex-column justify-content-center align-items-center">
    <img src="../../../assets/images/borrower/jinja.webp" class="borrowerImage" />
    <img src="../../../assets/images/borrower/green_arrow.png" class="greenArrow" />
    <h4 id="h4" style="font-weight: 300;">
      {{'Flexible & easy' | translate}} <br />
      {{'to apply online' | translate}}
    </h4>
  </div>

  <div class="flex flex-column justify-content-center align-items-center">
    <img src="../../../assets/images/borrower/canclehpuse.jpeg" class="borrowerImage" />
    <img src="../../../assets/images/borrower/green_arrow.png" class="greenArrow" />
    <h4 id="h4" style="font-weight: 300;">
      {{'Flexible & easy' | translate}} <br />
      {{'to apply online' | translate}}
    </h4>
  </div>
</div>

<h1 class="text-center">
  {{'How it works' | translate}} {{'?'  | translate}}
</h1>


<div class="whatis_card_parent">
  <div class="whatis_card">
    <h2>{{'apply-for-a-loan' | translate}}</h2>
    <h3>{{'apply-for-a-loan' | translate}}</h3>
    <p>
      Fill in & attach documents to complete your loan application online
    </p>
    <button  *ngIf="isAuthenticated" [routerLink]="['/borrow-registration']">Start Now</button>
  </div>

  <div class="whatis_card">
    <h3>{{'Our team follow up' | translate}}</h3>
    <h2>{{'Our team follow up' | translate}}</h2>
    <p>
      Fill in & attach documents to complete your loan application online
    </p>
    <button   *ngIf="isAuthenticated" [routerLink]="['/borrow-registration']" >Start Now</button>
  </div>

  <div class="whatis_card">
    <h2>{{'Decision Making' | translate}}</h2>
    <h3>{{'Decision Making' | translate}}</h3>
    <p>
      Fill in & attach documents to complete your loan application online
    </p>
    <button  *ngIf="isAuthenticated" [routerLink]="['/borrow-registration']">Start Now</button>
  </div>

  <div class="whatis_card">
    <h2>{{'Sign the loan' | translate}}</h2>
    <h3>{{'Sign the loan' | translate}}</h3>
    <p>
      Fill in & attach documents to complete your loan application online
    </p>
    <button  *ngIf="isAuthenticated" [routerLink]="['/borrow-registration']"> Start Now</button>
  </div>

  <div class="whatis_card">
    <h2>{{'Donation' | translate}}</h2>
    <h3>{{'What Is Donation' | translate}} {{'?' | translate}} </h3>
    <p>
      {{'donation-par' | translate}}
    </p>
    <button  *ngIf="isAuthenticated" [routerLink]="['/borrow-registration']">Start Now</button>
  </div>
</div>




<div>
  <h1 class="text-center" style="margin: 3% 0%;">
    {{'We cover all your business needs with' | translate}} <br /> {{'a various financial products' | translate}}
  </h1>
  
  <div id="container4" class="flex-container">
    <div class="coverAllBusiness">
      <h4>{{'Invoice Financing' | translate}}</h4>
    </div>
    <div class="line"></div>
    <div class="coverAllBusiness">
      <h4>{{'Working Capital' | translate}}</h4>
    </div>
    <div class="line"></div>
    <div class="coverAllBusiness">
      <h4>{{'Point of Sale (POS)' | translate}}</h4>
    </div>
    <div class="line"></div>
    <div class="coverAllBusiness">
      <h4>{{'Cheque Discounting' | translate}}</h4>
    </div>
  </div>
  
</div>

<div class="text-center flex justify-content-center Wondering_section">
  <div style="margin: 0 auto;">
    <h2>{{'Wondering why you should Borrow with Crowd' | translate}}<span style="color: #71DBAC;">{{'X' | translate}}</span>{{'?' | translate}}</h2>
    <p class="wondering_text">
      You need to upgrade your business, but you don’t have enough credit?
      You want to extend your building to make extra space for your business and you need money? You need to feel safe borrowing in the most secured place?
    </p>
    <button>
      Learn more
    </button>
  </div>
</div>


<app-downloadapp></app-downloadapp>
