<p-progressBar *ngIf="httpLoading" [style]="{ height: '6px' }" color="#FF2E63" mode="indeterminate"
  [style]="{'height': '6px'}"></p-progressBar>
<div class="flex flex-column shadow-2 surface-ground navBar">
  <div class="navbar p-2   flex align-items-center justify-content-between relative lg:static">
    <a class="no-underline" [routerLink]="['/']">
      <img src="assets/images/logo/futeric.svg" alt="futeric" height="40px" width="40px" class="mr-0 logo-img">
    </a>


    <a pRipple [routerLink]="['/p2p']" class="flex p-3 align-items-center no-underline"
      *ngIf="isAuthenticated && userTypeInvestor === true" (click)="setActiveLink('/p2p')"
      [ngClass]="{'active-link': isLinkActive('/p2p')}">
      <span class="text-base text-white">
        {{'Projects' | translate:param}}
      </span>
    </a>

  
    <a pRipple  [routerLink]="['/borrower/borrower-portal']" class="flex p-3 align-items-center no-underline"
      *ngIf="isAuthenticated && borrowerPage" (click)="setActiveLink('/borrower/borrower-portal')"
      [ngClass]="{'active-link': isLinkActive('/borrower/borrower-portal')}">
      <span class="text-base text-white">
        {{'borrower-page' | translate}}
      </span>
    </a>

    <a pRipple [routerLink]="['/my-wallet']" class="flex p-3 align-items-center no-underline"
      *ngIf="isAuthenticated && userTypeInvestor === true"  (click)="setActiveLink('/my-wallet')"
      [ngClass]="{'active-link': isLinkActive('/my-wallet')}">
      <span class="text-base text-white">
        {{'my-wallet' | translate}}
      </span>
    </a>

    <a pRipple [routerLink]="['/invest/p2p-details']" class="flex p-3 align-items-center no-underline"
      *ngIf="isAuthenticated && userTypeInvestor === true" (click)="setActiveLink('/invest/p2p-details')"
      [ngClass]="{'active-link': isLinkActive('/invest/p2p-details')}">
      <span class="text-base text-white">
        {{'My Investments' | translate:param}}
      </span>
    </a>

    <a pRipple [routerLink]="['/invest/overview']" class="flex p-3 align-items-center no-underline"
      *ngIf="isAuthenticated && userTypeInvestor === true"  (click)="setActiveLink('/invest/overview')"
      [ngClass]="{'active-link': isLinkActive('/invest/overview')}">
      <span class="text-base text-white">
        {{'overview' | translate:param}}
      </span>
    </a>

    <a pRipple [routerLink]="['/about']" class="flex p-3 align-items-center no-underline"
      *ngIf="!isAuthenticated || !userTypeInvestor || !userTypeBorrower" (click)="setActiveLink('/about')"
      [ngClass]="{'active-link': isLinkActive('/about')}">
      <span class="text-base text-white">
        {{'about' | translate }}
      </span>
    </a>

    <div class="borrowMenu" *ngIf="isAuthenticated && !userTypeInvestor === true || !isAuthenticated">
      <span (click)="setActiveLink('/invest-n')"
      [ngClass]="{'active-link': isLinkActive('/invest-n')}" class="text-base	text-white flex p-3 align-items-center no-underline cursor-pointer"
        [routerLink]="['/invest-n']">
        {{'invest' | translate }}

        <img src="../../../../../assets/images/icons/down-arrow.png" style="width: 14px; height: 14px;" />
      </span>

      <div class="dropdownMenu">
        <small  class="subMenu" (click)="logInIfNotElseNavigateToInvestor()">
          {{'InvestNow' | translate }}
        </small>
        <small (click)="setActiveLink('/term-finance')"
        [ngClass]="{'active-link': isLinkActive('/term-finance')}" class="subMenu" [routerLink]="['/term-finance']">
          {{'Peer2PeerCX' | translate }}
        </small>
        <!-- <small class="subMenu" [routerLink]="['/borrower-calculator']">
          Real Estate CX
        </small> -->
        <small (click)="setActiveLink('/borrower-calculator')"
        [ngClass]="{'active-link': isLinkActive('/borrower-calculator')}" class="subMenu" [routerLink]="['/borrower-calculator']">
          {{'Calculator' | translate }}
        </small>
      </div>
    </div>


    <div class="borrowMenu" *ngIf="isAuthenticated && !userTypeBorrower === true || !isAuthenticated">
      <span  class="text-base text-white flex p-3 align-items-center no-underline cursor-pointer"
       >
        {{'borrow' | translate }}
        <img src="../../../../../assets/images/icons/down-arrow.png" style="width: 14px; height: 14px;" />
      </span>

      <div class="dropdownMenu">
        <small  class="subMenu" (click)="logInIfNotElseNavigateToBorrower()">
          {{'BorrowNow' | translate }}
        </small>
        <small (click)="setActiveLink('/term-finance')"
        [ngClass]="{'active-link': isLinkActive('/term-finance')}" class="subMenu" [routerLink]="['/term-finance']">
          {{'TermFinance' | translate }}
        </small>
        <small (click)="setActiveLink('/borrower-calculator')"
        [ngClass]="{'active-link': isLinkActive('/borrower-calculator')}" class="subMenu" [routerLink]="['/borrower-calculator']">
          {{'Calculator' | translate }}
        </small>
      </div>
    </div>


    <a pRipple (click)="setActiveLink('/borrow')"
    [ngClass]="{'active-link': isLinkActive('/borrow')}" [routerLink]="['/borrow']" class="flex p-3 align-items-center no-underline"
      *ngIf="!isAuthenticated || !userTypeInvestor || !userTypeBorrower">
      <span class="text-base  text-white">
        {{'how-it-works' | translate }}
      </span>
    </a>



    <div *ngIf="!isAuthenticated" class="flex align-items-center loginSignUpBtn btnStyle">
      <a pRipple class="flex p-3 align-items-center no-underline cursor-pointer " (click)="openAuthDialog(1)">
        <span class="text-base">
          {{'login' | translate:param }}
        </span>
      </a>
      /
      <a pRipple class="flex p-3 align-items-center textStyle no-underline cursor-pointer" (click)="openAuthDialog(0)">
        <span class="text-base textStyle">
          {{'sign-up' | translate:param }}
        </span>
      </a>


    </div>

    <div class="flex align-items-center gap-2" style="width:4%">
      <span class="cursor-pointer text-base	" (click)="setActiveLang({id: 1,name: 'ar'})">
        AR
      </span>
      |
      <span class="cursor-pointer text-base	" (click)="setActiveLang({id: 1,name: 'en'})">
        EN
      </span>
    </div>

    <a pRipple class="cursor-pointer block lg:hidden text-white" pStyleClass="@next" enterClass="hidden"
      leaveToClass="hidden" [hideOnOutsideClick]="true">
      <i class="pi pi-bars text-4xl"></i>
    </a>


    <div class="z-1 shadow-1 border-circle mr-2">

      <ul
        class="profile-list list-none p-0 m-0 flex justify-content-end lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none ">
        <li class="lg:relative" *ngIf="isAuthenticated">
          <a pRipple
            class="flex px-6 p-3 lg:px-3 align-items-center font-medium  cursor-pointer transition-colors transition-duration-150"
            pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
            leaveActiveClass="fadeout" [hideOnOutsideClick]="true">

            <img src="assets/images/icons/profile.svg" class="profileImage" width="20px" height="20px" alt="profile">
          </a>
          <ul
            class="list-none py-0 px-6 m-0 lg:px-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">


            <!-- <li>
              <a pRipple [routerLink]="['/portfolio']" class="flex p-3 align-items-center no-underline">
                <span class="font-medium text-dark-blue">{{'portfolio' | translate:param}}</span>
              </a>
            </li> -->

            <li>
              <a pRipple [routerLink]="['/settings']" class="flex p-3 align-items-center no-underline">
                <span class="font-medium text-dark-blue">{{'settings' | translate:param}}</span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/invest/notifications']" pRipple class="flex p-3 align-items-center no-underline">

                <span class="font-medium text-dark-blue">{{'notifications' | translate:param}}</span>
              </a>
            </li>

            <li>
              <a (click)="signOut()" pRipple class="flex p-3 align-items-center">

                <span class="font-medium text-dark-blue">{{'logout' | translate:param}}</span>
              </a>
            </li>
          </ul>
        </li>




      </ul>

    </div>
  </div>
</div>

<i *ngIf="showSwitch" class="pi pi-arrow-right-arrow-left switchIcon" (click)="onSwitch()">
  Switch To {{ switchTo }}
</i>

<app-auth-dialog [visible]="authDialogVisible" [activeIndex]="activeIndex"
  (onCloseDialog)="closeAuthDialog($event)"></app-auth-dialog>



<nav class="mobileNav">
  <a class="no-underline" [routerLink]="['/']">
    <img src="assets/images/logo/futeric.svg" alt="futeric" height="40px" width="40px" class="mr-0 logo-img">
  </a>
  <div class="dropdown">
    <button class="dropbtn">{{'EN' | translate}}</button>
    <div class="dropdown-content" style="left:0;">
    <a (click)="setActiveLang({id: 1,name: 'en'})">EN</a>
    <a (click)="setActiveLang({id: 1,name: 'ar'})">AR</a>
    </div>
  </div>
  <i class="pi pi-align-justify burgerIcon" (click)="toggleMobileNav()" style="z-index: 2;"></i>
</nav>

<div *ngIf="showMobileMenu" class="mobileMenu">
  <i *ngIf="selectedLanguage?.name !== 'ar'" class="pi pi-times" (click)="toggleMobileNav()"></i>
<ul>
  <li [routerLink]="['/p2p']" *ngIf="isAuthenticated && userTypeInvestor === true">{{'Projects' | translate:param}}</li>
  <li [routerLink]="['/my-wallet']" *ngIf="isAuthenticated && userTypeInvestor === true">{{'my-wallet' | translate:param}}</li>
  <li [routerLink]="['/invest/p2p-details']" *ngIf="isAuthenticated && userTypeInvestor === true">{{'My Investments' | translate:param}}</li>
  <li [routerLink]="['/invest/overview']" *ngIf="isAuthenticated && userTypeInvestor === true">{{'overview' | translate:param}}</li>
  <li [routerLink]="['/about']" *ngIf="!isAuthenticated || !userTypeInvestor || !userTypeBorrower">{{'about' | translate:param }}</li>
  <li [routerLink]="['/invest-n']" *ngIf="isAuthenticated && !userTypeInvestor === true || !isAuthenticated">{{'invest' | translate:param }}</li>
  <li (click)="logInIfNotElseNavigateToInvestor()">{{'InvestNow' | translate:param }}</li>
  <li [routerLink]="['/term-finance']">{{'Peer2PeerCX' | translate:param }}</li>
  <li [routerLink]="['/borrower-calculator']">{{'Calculator' | translate:param }}</li>
  <li [routerLink]="['/borrow']" (click)="logInIfNotElseNavigateToBorrower()">{{'BorrowNow' | translate:param }}</li>
  <li [routerLink]="['/term-finance']">{{'TermFinance' | translate:param }}</li>
  <li [routerLink]="['/borrower-calculator']">{{'Calculator' | translate:param }}</li>
  <li [routerLink]="['/borrow']" *ngIf="!isAuthenticated || !userTypeInvestor || !userTypeBorrower">
    {{'how-it-works' | translate:param }}
  </li>
  <li *ngIf="!isAuthenticated" (click)="openAuthDialog(1)">{{'login' | translate:param }}</li>
  <li *ngIf="!isAuthenticated" (click)="openAuthDialog(0)">{{'sign-up' | translate:param }}</li>
  <li *ngIf="isAuthenticated" (click)="signOut()">{{'logout' | translate:param }}</li>
</ul>
<i *ngIf="selectedLanguage?.name === 'ar'" class="pi pi-times" id="arBtn" (click)="toggleMobileNav()"></i>
</div>