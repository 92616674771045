import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';

interface City {
  name: string;
}

@Component({
  selector: 'app-invest-registration-company-details',
  standalone: true,
  imports: [MultiSelectModule, InputTextModule, FormsModule, ButtonModule,CheckboxModule, PrimeNgModule],
  templateUrl: './invest-registration-company-details.component.html',
  styleUrl: './invest-registration-company-details.component.scss'
})
export class InvestRegistrationCompanyDetailsComponent implements OnInit {
  cities!: City[];
  selectedCities: City[] = [];
  countriesString = ''; // Add this property to hold the selected countries as a string

  ngOnInit() {
    this.cities = [
      { name: 'Jordan' },
      { name: 'Kuwait' },
      { name: 'Oman' },
      { name: 'Qatar' },
      { name: 'Saudi Arabia' },
      { name: 'United Arab Emirates' },
      { name: 'Egypt' },
      { name: 'Algeria' },
      { name: 'Bahrain' },
    ];
  }

  onAddButtonClick() {
    // Update the second input field with the selected countries
    this.countriesString = this.selectedCities.map(city => city.name).join(', ');
  }
}
