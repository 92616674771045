import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-borrower-portal',
  standalone: true,
  imports: [CardModule,RouterOutlet],
  templateUrl: './borrower-portal.component.html',
  styleUrl: './borrower-portal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BorrowerPortalComponent {

}
