import { Component, OnInit } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { DownloadappComponent } from 'src/app/shared/downloadapp/downloadapp.component';
import { MainsliderComponent } from 'src/app/shared/mainslider/mainslider.component';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-about',
  standalone: true,
  imports: [CarouselModule,DownloadappComponent,MainsliderComponent,TranslateModule],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
})
export class AboutComponent implements OnInit {
  responsiveOptions: any[] | undefined;
  products: any[];
  mainSlider: any[];
  ngOnInit() {
    this.products = [
      {
        paragraph:'Seeking funds or eager to invest? CrowdX delivers!',
        image: '../../../assets/images/about/house.png',
        inventoryStatus: 'In Stock',
      },
      {
        paragraph: 'Interested in maximizing your returns? CrowdX offers exclusive investment avenues!',
        image: '../../../assets/images/about/capitalism.png',
        inventoryStatus: 'Out of Stock',
      },
      {
        paragraph: 'Need capital to grow your business? CrowdX is the key!',
        image: '../../../assets/images/about/token.png',
        inventoryStatus: 'Low Stock',
      }

    ];
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }


}
