import { Component } from '@angular/core';

@Component({
  selector: 'app-learn-registration',
  standalone: true,
  imports: [],
  templateUrl: './learn-registration.component.html',
  styleUrl: './learn-registration.component.scss'
})
export class LearnRegistrationComponent {

}
