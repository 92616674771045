import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { AuthDialogComponent } from 'src/app/partials/auth/auth-dialog';
import { Router, RouterLink, NavigationEnd } from '@angular/router';
import { HttpLoadingService } from 'src/app/shared/services/http-loading';

import { AuthService } from 'src/app/core/auth';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { unsubscribe } from 'src/app/core/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { LanguageService } from 'src/app/core/language';
import { Language } from 'src/app/pages/settings/language';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgModule,
    RouterLink,
    AuthDialogComponent,
    TranslateModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, OnDestroy {
  authDialogVisible: boolean = false;
  activeIndex: number | null;
  httpLoading: boolean = false;
  isAuthenticated: boolean;
  items: MenuItem[] | undefined;
  languages: Language[];
  selectedLanguage: Language;
  userTypeInvestor: any;
  userTypeBorrower: any
  showMenu: boolean = false;
  showSwitch: boolean = false;
  switchTo: any;
  showMobileMenu: boolean = false
  activeLink = localStorage.getItem('activeLink') || '';
  borrowerPage: boolean = false;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private _httpLoadingService: HttpLoadingService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService,
    private _languageService: LanguageService,
    private _translateService: TranslateService,
    private _router: Router,
    private _httpClient: HttpClient,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._initializeUserType();
    this._subscribeToRouterEvents();

    const getIfUserHaveProfile: any = localStorage.getItem('profileType');
    const parsedDataIfUserHaveData = JSON.parse(getIfUserHaveProfile)
    if (getIfUserHaveProfile) {
      if (parsedDataIfUserHaveData.investor && parsedDataIfUserHaveData.borrower) {
        this.showSwitch = true
      }
    }
  }
  /**
   * On Init
   */
  ngOnInit(): void {
    this._showHttpLoading();
    this._isUserAuthenticated();
    this.switchToFunction();
    this.items = [
      {
        label: 'File',
        icon: 'pi pi-fw pi-file',
        items: [
          {
            label: 'Delete',
            icon: 'pi pi-fw pi-trash',
          },
          {
            separator: true,
          },
          {
            label: 'Export',
            icon: 'pi pi-fw pi-external-link',
          },
        ],
      },
    ];

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showMobileMenu = false;
      }
    });
  }

  isProfileCompleted(): boolean {
    const profileIsCompleted = localStorage.getItem('profileIsCompleted');
    return profileIsCompleted === 'no';
  }


  /**
   * On Destroy
   */

  
  ngOnDestroy(): void {
    unsubscribe(this._unsubscribeAll);
  }

  setActiveLink(link: string) {
    this.activeLink = link;
    localStorage.setItem('activeLink', link);
  }

  isLinkActive(link: string): boolean {
    return this.activeLink === link;
  }

  /**
   * Sign Out
   */
  signOut(): void {
    localStorage.clear();
    this._authService.signOut().subscribe(() => {
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * Open Auth Dialog
   * @param {number}activeIndex
   */

  openAuthDialog(activeIndex: number): void {
    this.authDialogVisible = true;
    this.activeIndex = activeIndex;
    this.showMobileMenu = false;
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Close Auth Dialog
   * @param {boolean}isVisibile
   */
  closeAuthDialog(isVisibile: boolean): void {
    this.authDialogVisible = isVisibile;
    this.activeIndex = null;
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Is User Authenticated
   */

  private _isUserAuthenticated(): void {
    this._authService
      .isAccessAllowed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * Show Http Loading
   */

  private _showHttpLoading(): void {
    this._httpLoadingService.httpProgress().subscribe((status: boolean) => {
      if (status) {
        setTimeout(() => {
          this.httpLoading = true;
          this._changeDetectorRef.markForCheck();
        }, 0);
      } else {
        setTimeout(() => {
          this.httpLoading = false;
          this._changeDetectorRef.markForCheck();
        }, 1000);
      }
    });
  }

  setActiveLang(lang: Language): void {
    if (lang) {
      this.selectedLanguage = lang;
      this._languageService.activeLang = lang;
      this._translateService.use(lang.name);
      this.setLayoutDirection(lang.name);
      this._changeDetectorRef.markForCheck();
    }
  }

  setLayoutDirection(lang: string): void {
    let htmlTag = this._document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    htmlTag.dir = lang === 'ar' ? 'rtl' : 'ltr';
    this._changeDetectorRef.markForCheck();
  }

  showMenuFun(): void {
    this.showMenu = !this.showMenu;
  }

  logInIfNotElseNavigateToInvestor() {
    let isLogedInn = localStorage.getItem('accessToken');
    if (!isLogedInn) {
      this.authDialogVisible = true;
    } else {
      this._router.navigate(['/invest/invest-registration']);
    }
  }

  logInIfNotElseNavigateToBorrower() {
    let isLogedInn = localStorage.getItem('accessToken');
    if (!isLogedInn) {
      this.authDialogVisible = true;
    } else {
      this._router.navigate(['/borrower/borrow-registration']);
    }
  }

  private _initializeUserType() {
    const userType: any = localStorage.getItem('profileType');
    const parsedData = JSON.parse(userType);
    if(userType){
      if (parsedData.borrower && parsedData.investor == null) {
        this.borrowerPage = true
      }
    }
    if (parsedData) {
      if (parsedData.investor) {
        this.userTypeInvestor = parsedData.investor;
      }
      if (parsedData.borrower) {
        this.userTypeBorrower = parsedData.borrower;
      }
    }
    this._changeDetectorRef.markForCheck()
  }

  private _subscribeToRouterEvents() {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.switchToFunction();
      });
  }

  switchToFunction() {
    const investorRoutes = [
      /^\/p2p$/,
      /^\/invest\/[^/]+\/[^/]+$/,
      /^\/my-wallet$/,
      /^\/invest\/p2p-investments$/,
      /^\/portfolio$/,
      /^\/invest\/p2p-details$/
    ];
    const borrowerRoutes = [
      /^\/borrower\/borrower-portal$/
    ];

    const currentUrl = this._router.url;

    if (this.userTypeInvestor && this.userTypeBorrower) {
      if (investorRoutes.some(route => route.test(currentUrl))) {
        this.switchTo = 'Borrower Portal';
      } else if (borrowerRoutes.some(route => route.test(currentUrl))) {
        this.switchTo = 'Investor Portal';
      }
    }

    console.log(this._router.url);
  }

  onSwitch() {
    if (this.switchTo === 'Borrower Portal') {
      this._router.navigate(['/borrower/borrower-portal']);
    } else if (this.switchTo === 'Investor Portal') {
      this._router.navigate(['/p2p']);
    }
  }

  toggleMobileNav() {
    this.showMobileMenu = !this.showMobileMenu;
  }

}
