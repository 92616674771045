<div class="mt-6 mainSlider">
  <div class="card">
    <p-carousel [value]="mainSlider" autoplayInterval="1800" numVisible="1" numScroll="1" circular="false" showIndicators="false" showNavigators="false">
      <ng-template let-product pTemplate="item">
        <div class="mainSliderInside" style="margin: auto;height: 70vh; width: 100%; background-color: #fcfcfc; display: flex; flex-direction: row; border: 1px solid #71DBAC; border-radius: 10px;">
          <img [src]="product.image" [alt]="CrowedX" class="image" style="width: 100%;height: 100%; object-fit: cover;border-radius: 10px;" />
        </div>
      </ng-template>
    </p-carousel>
  </div>
</div>
