import { SignInData } from 'src/app/partials/auth/login';
import { SignUpData } from 'src/app/partials/auth/sign-up';
import {environment} from "../../../../src/environments/environment"

export const signUpData = (user: SignUpData) => {
    return {
      "appid": `${environment.cmpCode}`,
      "provider": "password",
      "customerType": 1,
      "onBoardingType": 1,
      "programCode": "1",
      "vipFlag": 0,
      "kycStatus": 0,
      "kycRemark": "",
      "picture": "base46",
      "regMobileISDNCode": "+962",
      "regMobileNumber":"+962" + user.phoneNumber,
      "verType": "otp",
      "verWay": "E",
      "languagePreference": "en",
      "token": `${user.email}:${user.fullName}:${user.password}`
  }

}


export const companyData = {
    "appid":`${environment.cmpCode}`,
    "provider": "password",
    "token": "crowdlending1@mailinator.com::Admin@123!"
}

export const signInData = (credentials: SignInData) => {
    return {
        "appid": `${environment.cmpCode}`,
        "provider": "password",
        "token": `${credentials.email}::${credentials.password}`
    }
}


