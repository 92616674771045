export const INVEST_ROUTE_PATH = 'invest';
export const INVESTMENTS_ENDPOINT = 'investmentsall';
export const SECTORS_FILTER_ENDPOINT = 'sectors/sector';
export const INVEST_ENDPOINT = 'investments/invest';
export const SUFFICIENT_AMOUNT_ENDPOINT = 'invest/amount-check';





