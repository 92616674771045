export const invests = [
    {
        id: 1,
        title: 'test',
        description: 'jdkaasdasdas asdadasdmasdada',
        profilePhoto: 'assets/images/avatar/blog/blog-1.png',
        coverImage: 'assets/images/blog/blog-1.png',
        sector: 'Agriculture',
        investmentGrade: 'High',
        duration: '12',
        daysLeft: '12',
        roi: '10%',
        points: '1.000',
        progress: 90,
        shortfall: 10
    },
    {
        id: 2,
        title: 'test1',
        description: 'jdkaasdasdas asdadasdmasdada',
        profilePhoto: 'assets/images/avatar/blog/blog-2.png',
        coverImage: 'assets/images/blog/blog-2.png',
        sector: 'Agriculture',
        investmentGrade: 'Med',
        duration: '12',
        daysLeft: '12',
        roi: '10%',
        points: '1.000',
        progress: 20,
        shortfall: 10
    },
    {
        id: 3,
        title: 'test',
        description: 'jdkaasdasdas asdadasdmasdada',
        profilePhoto: 'assets/images/avatar/blog/blog-3.png',
        coverImage: 'assets/images/blog/blog-3.png',
        sector: 'Agriculture',
        investmentGrade: 'Low',
        duration: '12',
        daysLeft: '12',
        roi: '10%',
        points: '1.000',
        progress: 60,
        shortfall: 10
    },
    {
        id: 4,
        title: 'test',
        description: 'jdkaasdasdas asdadasdmasdada',
        profilePhoto: 'assets/images/avatar/blog/blog-4.png',
        coverImage: 'assets/images/blog/blog-4.png',
        sector: 'Real Estate',
        investmentGrade: 'Low',
        duration: '12',
        daysLeft: '12',
        roi: '10%',
        points: '1.000',
        progress: 50,
        shortfall: 10
    },
    {
        id: 5,
        title: 'test',
        description: 'jdkaasdasdas asdadasdmasdada',
        profilePhoto: 'assets/images/avatar/blog/blog-5.png',
        coverImage: 'assets/images/blog/blog-5.png',
        sector: 'Real Estate',
        investmentGrade: 'Low',
        duration: '12',
        daysLeft: '12',
        roi: '10%',
        points: '1.000',
        progress: 70,
        shortfall: 10
    },
    {
        id: 6,
        title: 'test',
        description: 'jdkaasdasdas asdadasdmasdada',
        profilePhoto: 'assets/images/avatar/blog/blog-5.png',
        coverImage: 'assets/images/blog/blog-5.png',
        sector: 'Real Estate',
        investmentGrade: 'Low',
        duration: '12',
        daysLeft: '12',
        roi: '10%',
        points: '1.000',
        progress: 70,
        shortfall: 10
    },
    {
        id: 7,
        title: 'test',
        description: 'jdkaasdasdas asdadasdmasdada',
        profilePhoto: 'assets/images/avatar/blog/blog-5.png',
        coverImage: 'assets/images/blog/blog-5.png',
        sector: 'Real Estate',
        investmentGrade: 'Low',
        duration: '12',
        daysLeft: '12',
        roi: '10%',
        points: '1.000',
        progress: 70,
        shortfall: 10
    },
    {
        id: 8,
        title: 'test',
        description: 'jdkaasdasdas asdadasdmasdada',
        profilePhoto: 'assets/images/avatar/blog/blog-5.png',
        coverImage: 'assets/images/blog/blog-5.png',
        sector: 'Real Estate',
        investmentGrade: 'Low',
        duration: '12',
        daysLeft: '12',
        roi: '10%',
        points: '1.000',
        progress: 70,
        shortfall: 10
    }
]

export const investmentDetails = {
    id: 1,
    title: 'Horizon Heights Apt.',
    description: 'Your shopping mall investment is delivering as promised, generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow generating consistent income from its prime location. Stay updated with reports to track your earnings and watch your investment grow.',
    profilePhoto: "assets/images/avatar/blog/blog-1.png",
    coverImage: "assets/images/blog/blog-5.png",
    sector: 'Real Estate',
    investmentGrade: 'Low',
    points: '1.000',
    roi: '10',
    daysLeft: '12',
    progress: 70,
    duration: '12',
    video: 'https://www.youtube.com/embed/Dugn51K_6WA',
    companyValuation: '1,660,000.00',
    goal: '250,000.00',
    valuePerDigitalShare: '0.000100',
    maximumShareEquity: '0.000133',
    documents: [
        {
            name: 'Pitch Deck',
            file: ''
        },
        {
            name: 'Executive Summary',
            file: ''
        }
    ],
    relatedProjects: [
        {
            "id": 1,
            "title": "personal",
            "description": "Cars",
            "profilePhoto": "",
            "coverImage": "",
            "sector": "Real state",
            "investmentGrade": "Low",
            "duration": 24,
            "daysLeft": "30",
            "roi": 10,
            "points": 11,
            "progress": 90,
            "shortFall": 10,
            "prodKey": "400-1-1-68-2"
        },
        {
            "id": 2,
            "title": "personal",
            "description": "Cars",
            "profilePhoto": "",
            "coverImage": "",
            "sector": "Machines",
            "investmentGrade": "Medium",
            "duration": 24,
            "daysLeft": "366",
            "roi": 10,
            "points": 11,
            "progress": 90,
            "shortFall": 10,
            "prodKey": "400-1-1-68-3"
        },
        {
            "id": 3,
            "title": "personal",
            "description": "Cars",
            "profilePhoto": "",
            "coverImage": "",
            "sector": "Agriculture",
            "investmentGrade": "High",
            "duration": 24,
            "daysLeft": "30",
            "roi": 10,
            "points": 11,
            "progress": 90,
            "shortFall": 10,
            "prodKey": "400-1-1-68-4"
        },
    ]
}
