<p-toast></p-toast>


<img src="/assets/images/icons/password.png" style="width: 16%; margin-left: 42%;" />
<p style="color: #bababa; text-align: center;margin-top: 1%; "> {{'otpsent' | translate}} {{userRegEmail}}</p>


<div class="otpContainer">
  <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}"></ng-otp-input>
</div>


<button class="verifyBtn" (click)="verifyOtpCode()" [disabled]="otpDisabled">
  {{'verify' | translate}}
</button>
