import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api'; // Import MessageService and MessageModule

@Component({
  standalone: true,
  imports: [NgOtpInputModule, TranslateModule, ToastModule], // Include MessageModule
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService], // Provide MessageService
})
export class OtpComponent implements OnInit {
  @Output() onOTPSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  private httpClientWithoutInterceptor: HttpClient;

  constructor(
    private handler: HttpBackend,
    private httpClient: HttpClient,
    private router: Router,
    private messageService: MessageService // Inject MessageService
  ) {
    this.httpClientWithoutInterceptor = new HttpClient(handler);
  }

  otp: number = 0;
  errors: any = 0;
  isOTPSuccess: boolean = false;
  otpDisabled: boolean = false;
  userRegEmail: any;

  ngOnInit(): void {
    this.userRegEmail = localStorage.getItem('reg_user_email');
  }

  onOtpChange(otp: number): void {
    this.otp = otp;
    console.log('OTP changed:', otp);
  }

  async verifyOtpCode(): Promise<void> {
    try {
      const otpTrailId = localStorage.getItem('reg_otpTrail_id');
      const apiUrl = `${environment.platformServerOrigin}v1/_otp/${otpTrailId}/${this.otp}`;

      this.otpDisabled = true;

      const accessToken = localStorage.getItem('reg_user_access_token');

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await this.httpClientWithoutInterceptor
        .get(apiUrl, { headers })
        .toPromise();

      //@ts-ignore
      if (response.valid) {
        this.onOTPSuccess.emit(true);
        this.isOTPSuccess = true;
      } else {
        //@ts-ignore
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'OTP is not valid',
        }); // Show toast error message
        this.otpDisabled = false;
      }
      console.log('HTTP Response:', response);
    } catch (error) {
      console.error('Error:', error);
      this.errors = error;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while verifying OTP',
      }); // Show toast error message
    } finally {
      this.otpDisabled = false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.isOTPSuccess) {
      $event.returnValue = true;
    }
  }

  @HostListener('window:unload', ['$event'])
  onUnload($event: any): void {
    if (!this.isOTPSuccess) {
      localStorage.clear();
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState($event: any): void {
    if (!this.isOTPSuccess) {
      const confirmation = window.confirm(
        'You have unsaved changes. Do you really want to leave?'
      );
      if (confirmation) {
        localStorage.clear();
        this.router.navigate(['/']);
      } else {
        history.pushState(null, '', location.href);
      }
    }
  }
}
