import { Route, Routes } from '@angular/router';
import { LayoutComponent } from './layout';
import { INVEST_ROUTE_PATH } from './pages/invest';
import { PORTFOLIO_ROUTE_PATH } from './pages/portfolio';
import { WALLET_ROUTE_PATH } from './pages/wallet';
import { NOT_FOUND_ROUTE_PATH } from './pages/error/error-404';
import { initialDataResolver } from './app.resolver';
import { AuthGuard } from './core/auth/guards';
import { SOMTHING_WENT_WRONG_ROUTE_PATH } from './pages/error/something-went-wrong';
import { SETTINGS_ROUTE_PATH } from './pages/settings';
import { INVESTMENT_CALCULATOR_ROUTE_PATH } from './pages/investment-calculator';
import { InvestNewComponent } from './pages/invest-new/invest-new.component';
import { InvestRegistrationComponent } from './pages/invest-registration/invest-registration.component';

import { InvestRegistrationCompanyDetailsComponent } from './pages/invest-registration-company-details/invest-registration-company-details.component';

import { LearnRegistrationComponent } from './pages/learn-registration/learn-registration.component';
import { BorrowComponent } from './pages/borrow/borrow.component';
import { BorrowRegistrationComponent } from './pages/borrow-registration/borrow-registration.component';
import { BorrowRegistrationStepTwoComponent } from './pages/borrow-registration-step-two/borrow-registration-step-two.component';
import { BorrowRegistrationStepThreeComponent } from './pages/borrow-registration-step-three/borrow-registration-step-three.component';
import { BorrowRegistrationConfirmPhoneComponent } from './pages/borrow-registration-confirm-phone/borrow-registration-confirm-phone.component';
import { TeamFinanceComponent } from './pages/team-finance/team-finance.component';
import { CartComponent } from './pages/cart/cart.component';
import { P2pComponent } from './pages/p2p/p2p.component';
import { InvestListComponent } from './pages/p2p/list/list.component';
import { inject } from '@angular/core';
import { InvestService } from './pages/p2p/invest.service';

import { AboutComponent } from './pages/about/about.component';
import { CalculatorComponent } from './pages/investment-calculator/calculator/calculator.component';
import { BorrowerCalculatorComponent } from './pages/borrower-calculator/borrower-calculator.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { BorrowerPortalComponent } from './pages/borrower-portal/borrower-portal.component';
import { BORROWER_ROUTE_PATH } from './pages/borrower-portal/borrow.constants';

export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'borrower-portal',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { layout: 'horizontal' },
    children: [{ path: '', component: BorrowerPortalComponent }],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      layout: 'horizontal',
    },
    children: [
      {
        path: SETTINGS_ROUTE_PATH,
        loadChildren: () => import('./pages/settings/settings.routes'),
      },
      {
        path: INVEST_ROUTE_PATH,
        loadChildren: () => import('./pages/invest/invest.routes'),
      },
      {
        path: INVESTMENT_CALCULATOR_ROUTE_PATH,
        loadChildren: () =>
          import('./pages/investment-calculator/investment-calculator.routes'),
      },
      {
        path: BORROWER_ROUTE_PATH,
        loadChildren: () => import('./pages/borrower-portal/borrow.routes'),
      },
      {
        path: 'p2p',
        component: InvestListComponent,
        resolve: {
          investments: () => inject(InvestService).getInvestments(),
          sectors: () => inject(InvestService).getSectorsFilter(),
        },
      },
    ],
  },

  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'horizontal',
    },
    children: [
      { path: 'home', component: AboutComponent },
      { path: 'faqs', component: FaqsComponent },
      { path: 'about', component: AboutUsComponent },
      { path: 'invest-n', component: InvestNewComponent },

      {
        path: 'company-details',
        component: InvestRegistrationCompanyDetailsComponent,
      },

      { path: 'learn-more', component: LearnRegistrationComponent },
      { path: 'borrow', component: BorrowComponent },
      { path: 'borrow-registration', component: BorrowRegistrationComponent },
      {
        path: 'borrow-registration-step-two',
        component: BorrowRegistrationStepTwoComponent,
      },
      {
        path: 'borrow-registration-step-three',
        component: BorrowRegistrationStepThreeComponent,
      },
      {
        path: 'borrow-registration-phone-confirm',
        component: BorrowRegistrationConfirmPhoneComponent,
      },
      { path: 'term-finance', component: TeamFinanceComponent },

      { path: 'borrower-calculator', component: BorrowerCalculatorComponent },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      layout: 'horizontalWithBreadcrumb',
    },
    children: [
      {
        path: PORTFOLIO_ROUTE_PATH,
        loadChildren: () => import('./pages/portfolio/portfolio.routes'),
      },
      {
        path: WALLET_ROUTE_PATH,
        loadChildren: () => import('./pages/wallet/wallet.routes'),
      },
      {
        path: NOT_FOUND_ROUTE_PATH,
        pathMatch: 'full',
        loadChildren: () => import('./pages/error/error-404/error-404.routes'),
      },
    ],
  },

  {
    path: SOMTHING_WENT_WRONG_ROUTE_PATH,
    component: LayoutComponent,
    data: {
      layout: 'horizontal',
    },
    loadChildren: () =>
      import('./pages/error/something-went-wrong/something-went-wrong.routes'),
  },
  {
    path: '**',
    redirectTo: NOT_FOUND_ROUTE_PATH,
  },
] as Routes;
