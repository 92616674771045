<div *ngIf="loading" style="width: 50%; margin: auto;display: flex; justify-content: center;">
  <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
</div>
<div *ngIf="!loading" class="flex justify-content-center gap-4">
  <ng-container *ngIf="userType">
    <!-- Check if userType is Investor -->
    <p
      *ngIf="userType.investor"
      style="height: 100px; display: flex; justify-content: center; align-items: center; text-align: center; border-radius: 8px;"
      class="shadow-1 p-3 cursor-pointer"
      (click)="onNavigateToInvestor()"
    >
      Continue as Investor
    </p>
    <!-- Check if userType is Borrower -->
    <p
      *ngIf="userType.borrower"
      style="height: 100px; display: flex; justify-content: center; align-items: center; text-align: center; border-radius: 8px;"
      class="shadow-1 p-3 cursor-pointer"
      (click)="onNavigateToBorrower()"
    >
      Continue as Borrower
    </p>
    <!-- Show create account options if respective profiles do not exist -->
    <p
      *ngIf="!userType.investor"
      style="height: 100px; display: flex; justify-content: center; align-items: center; text-align: center; border-radius: 8px;"
      class="shadow-1 p-3 cursor-pointer"
      (click)="onNavigateToCreateInvestor()"
    >
      Create Investor Account
    </p>
    <p
      *ngIf="!userType.borrower"
      style="height: 100px; display: flex; justify-content: center; align-items: center; text-align: center; border-radius: 8px;"
      class="shadow-1 p-3 cursor-pointer"
      (click)="onNavigateToCreateBorrower()"
    >
      Create Borrower Account
    </p>
  </ng-container>

  <!-- If no profile exists -->
  <ng-container *ngIf="!userType">
    <p
      style="height: 100px; display: flex; justify-content: center; align-items: center; text-align: center; border-radius: 8px;"
      class="shadow-1 p-3 cursor-pointer"
      (click)="onNavigateToCreateInvestor()"
    >
      Create Investor Account
    </p>
    <p
      style="height: 100px; display: flex; justify-content: center; align-items: center; text-align: center; border-radius: 8px;"
      class="shadow-1 p-3 cursor-pointer"
      (click)="onNavigateToCreateBorrower()"
    >
      Create Borrower Account
    </p>
  </ng-container>
</div>
