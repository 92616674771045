import { Component, OnInit } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { DownloadappComponent } from 'src/app/shared/downloadapp/downloadapp.component';
import { MainsliderComponent } from 'src/app/shared/mainslider/mainslider.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-borrower-calculator',
  standalone: true,
  imports: [CarouselModule, SliderModule, FormsModule, DownloadappComponent, MainsliderComponent, TranslateModule],
  templateUrl: './borrower-calculator.component.html',
  styleUrl: './borrower-calculator.component.scss',
})
export class BorrowerCalculatorComponent implements OnInit {
  //  number = 10000;
  loanValue: number = 10000;
  interestValue: number = 0.1; // Assuming interest rate is entered in decimal form (e.g., 10% = 0.1)
  months: number = 6;
  monthlyPayment: number = 0;
  totalRepayment: any;
  ngOnInit() {
    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }
  increaseMony() {
    if (this.loanValue < 10000) {
      this.loanValue += 100;
    }
    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }

  getCeiledInterestValue(): string {
    return this.interestValue.toFixed(2);
  }

  onChangeSlider(event: any) {
    const newValue = event.value;
    if (newValue > this.loanValue) {
      this.increaseMony();
      this.calculateTotalRepayment();
      this.calculateMonthlyPayment();
    } else{
      this.decreaseMony();
      this.calculateTotalRepayment();
     this.calculateMonthlyPayment();
    }  }

  onChangeRate(event :any){
    const newValue = event.value;
    if (newValue > this.interestValue) {
      this.increaseInterest();
      this.calculateTotalRepayment();
      this.calculateMonthlyPayment();
    } else  {
      this.decreaseInterest();
      this.calculateTotalRepayment();
      this.calculateMonthlyPayment();
    }
  }
  onChangeMonth(event :any){
 
    const newValue = event.value;
    if (newValue > this.months) {
      this.increaseMonth();
       this.calculateTotalRepayment();
       this.calculateMonthlyPayment();
    } else  {
      this.decreaseMonth();
      this.calculateTotalRepayment();
      this.calculateMonthlyPayment();
    }
  }
  decreaseMony() {
    if (this.loanValue > 1000) {
      this.loanValue -= 100;
    }
    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }

  increaseInterest() {
    if (this.interestValue < 0.18) { // Assuming the maximum interest rate is 18%
      this.interestValue += 0.01; // Increment by 1%
    }
    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }

  decreaseInterest() {
    if (this.interestValue > 0.1) { // Assuming the minimum interest rate is 10%
      this.interestValue -= 0.01; // Decrement by 1%
    }
    this.calculateTotalRepayment();
    this.calculateMonthlyPayment();
  }

  increaseMonth() {
    if (this.months < 36) { // Assuming the maximum loan tenure is 36 months
      this.months++;
      this.calculateTotalRepayment();
      this.calculateMonthlyPayment();
    }
  }

  decreaseMonth() {
    if (this.months > 1) { // Assuming the minimum loan tenure is 1 month
      this.months--;
      this.calculateTotalRepayment();
      this.calculateMonthlyPayment();
    }
  }

  calculateTotalRepayment() {
    const totalInterest = (this.loanValue * this.interestValue * this.months);
    const total = Math.round(this.loanValue + totalInterest);
    const totalRepayment = total;
    this.totalRepayment = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(totalRepayment);
  }

  calculateMonthlyPayment() {
    let repaymentString = this.totalRepayment;
    repaymentString = repaymentString.replace(/,/g, '');
    const repaymentNumber = parseFloat(repaymentString);
    this.monthlyPayment = repaymentNumber / this.months;
    this.monthlyPayment = Math.round(this.monthlyPayment * 100) / 100;
  }
}
