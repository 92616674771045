<div class="otp-container">

  <h1 style="color: #10194E; font-size: 20px; font-weight: 700;">New Borrower Registration</h1>
<h2 style="color:#10194E; font-weight: 400; font-size: 20px;">Confirm Phone</h2>
  <div class="flex align-items-center gap-1" style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px;">A one-time password has been sent to <h5 style="color: #425375; font-weight: 600; font-size: 16px;">+962790731353</h5></div>
  <span style="color: #FF2E63; font-size: 16px; font-weight: 400; text-decoration: underline;">Edit Phone Number.</span>
  <br>
  <br>
  <br>
  <span style="color: #425375; font-size: 16px; font-weight: 400; line-height: 25px;">One time password</span>
  <form style="display: flex; flex-direction: row; gap: 1%;">
    <div *ngFor="let digit of otpDigits; let i = index" style="display: flex; flex-direction: row;">

      <input type="text" [(ngModel)]="otpDigits[i]" name="otp{{ i }}" class="otp-input" maxlength="1" (ngModelChange)="onOtpInputChange(i)" style="border: 0.8px solid #B4C1DB; width: 60px; height: 60px; border-radius: 6px; margin-left: 2%; text-align: center;" />

    </div>

  </form>
  <div class="flex gap-4 align-items-center" style="margin-top: 2%;">

    <button [routerLink]="['/thank-you']" class="verifyBtn cursor-pointer" style="width: 134px; height: 50px; background-color: #16BE81; color: white; font-size: 20px; font-weight: 600; line-height: 26px; text-align: center; border: none; border-radius: 8px;">
      Verify
    </button>

    <p>
      Didn't receive a code? <u style="color: #FF2E63; font-size: 16px; font-size: 400">Resend code</u>
    </p>
  </div>
</div>
