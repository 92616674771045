import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators  , AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SignUpForm } from './sign-up.types';
import { PrimeNgModule } from 'src/app/shared/modules/prime-ng';
import { AuthService } from 'src/app/core/auth';
import { Subject, takeUntil } from 'rxjs';
import { unsubscribe } from 'src/app/core/utils';
import { StringOnlyDirectiveModule } from 'src/app/shared/directives/string-only-input';
import { NumberOnlyDirectiveModule } from 'src/app/shared/directives/number-only-input';
import { MustMatch } from 'src/app/core/helpers/validations/must-match';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-auth-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    PrimeNgModule,
    ReactiveFormsModule,
    StringOnlyDirectiveModule,
    NumberOnlyDirectiveModule,
    TranslateModule
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers:[MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpComponent implements OnInit, OnDestroy {
  @Output() onNavigateToSignIn: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  signUpForm: FormGroup<SignUpForm>
  formSubmitted: boolean = false;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService ,
    private translateService: TranslateService
  ) {
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    this._createSignUpForm();
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    unsubscribe(this._unsubscribeAll);
  }


  
   passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      
      if (!value) {
        return null;
      }
  
      const hasUpperCase = /[A-Z]+/.test(value);
      const hasLowerCase = /[a-z]+/.test(value);
      const hasNumeric = /[0-9]+/.test(value);
      const isValidLength = value.length >= 8;
  
      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && isValidLength;
  
      return !passwordValid ? { invalidPassword: true } : null;
    };
  }


noLeadingZeroValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null; // Return null if control is empty to allow required validator to handle it
    }

    const startsWithZero = /^0/.test(value);

    return startsWithZero ? { startsWithZero: true } : null;
  };
}

  /**
   * Create Sign Up Form
   */

  private _createSignUpForm(): void {
    this.signUpForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      phoneNumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(10) , this.noLeadingZeroValidator()]],
      // password: ['', [Validators.required, Validators.minLength(8), ]],
      password: ['', [Validators.required, this.passwordValidator()]],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }

  /**
  * Create User
  */
  private _createUser(): void {
    this._authService.signUp(this.signUpForm.getRawValue())
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        () => {
          this.onNavigateToSignIn.emit(true);
          this.signUpForm.reset();
          this._changeDetectorRef.markForCheck();
        },
        (error) => {
          if (error.status === 400 && error.error.message === 'Failed to authenticate user with \'password\'. Check if user is active.') {
            this.translateService.get(['Error', 'user-email-already-used']).subscribe((translations: { [key: string]: string }) => {
              this.messageService.add({ severity: 'error', summary: translations['Error'], detail: translations['user-email-already-used'] });
            });
          }
        }
      );
  }
  
  /**
  * Sign Up
  */
  signUp(): void {
    this.formSubmitted = true;
    if (this.signUpForm.invalid) {
      return;
    }
    this._createUser();
  }
}
