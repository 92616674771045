<app-mainslider></app-mainslider>

<div class='header my-5'>
  <div>
    <div class='content'>
      <div>
        <h1 class="worksForInvestorTitle my-5">
          {{'About Us' | translate}}

        </h1>
        <p>
          {{'about-us-par' | translate}}
        </p>
      </div>
      <div>

        <img src="../../../assets/images/about/aboutus1.jpg" alt="CrowedX" style="height: 80%;object-fit: cover;" />

      </div>
    </div>

  </div>
</div>


<div class='header my-5'>
  <div>
    <div class='content'>
      <div>
        <h1 class="worksForInvestorTitle my-5">
          {{'Our Mission' | translate}}
        </h1>
        <p>
          {{'our-mission-par' | translate}}
        </p>
      </div>
      <div>

        <img src="../../../assets/images/about/aboutus2.jpg" alt="CrowedX" style="height: 80%;object-fit: cover;" />

      </div>
    </div>

  </div>
</div>
<div class='header my-5'>
  <div>
    <div class='content'>
      <div>
        <h1 class="worksForInvestorTitle my-5">
          {{'About Us' | translate}}
        </h1>
        <p>
          {{'about-us-par' | translate}}
        </p>
      </div>
      <div>
        <img src="../../../assets/images/about/aboutus3.jpg" alt="CrowedX" />

      </div>
    </div>

  </div>
</div>



<app-downloadapp></app-downloadapp>