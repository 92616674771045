<app-mainslider></app-mainslider>

<h1 style="color:#10194E; font-weight: 700; font-size: 36px; line-height: 54px; text-align: center; margin-top: 3%;">
  {{ 'whyBorrowTitle' | translate }}
</h1>
<h4 class="text-center">{{ 'termFinanceByCrowd' | translate }}<span style="color: #71DBAC;">{{'X' | translate}}</span></h4>
<p class="text-center w-5 m-auto" style="font-weight: 400;">
  {{ 'termFinanceDescription' | translate }}
</p>

<div class="howItWorks">
  <p>
    {{ 'howItWorksParagraph1' | translate }}
  </p>
  <p>
    {{ 'howItWorksParagraph2' | translate }}
  </p>
</div>

<h1 style="color:#71DBAC; font-weight: 500; font-size: 20px; line-height: 24px; text-align: center;">
  {{ 'getInstantQuote' | translate }}
</h1>

<h1 style="color:#10194E; font-weight: 700; font-size: 36px; line-height: 54px; text-align: center; margin-top: 3%;">
  {{ 'simpleStepsTitle' | translate }}
</h1>
<h4 style="text-align: center;">{{ 'termFinanceByCrowd' | translate }}<span style="color: #71DBAC;">{{'X' | translate}}</span></h4>

<div class="stepsContainer">
  <div class="simpleSteps">
    <h1 class="coverAllBusiness">
      1
    </h1>
    <img src="../../../assets/images/borrower/green_arrow.png" class="greenArrow" />
    <p>
      {{ 'fillInDocuments' | translate }}
    </p>
  </div>

  <div class="simpleSteps">
    <h1 class="coverAllBusiness">
      2
    </h1>
    <img src="../../../assets/images/borrower/green_arrow.png" class="greenArrow" />
    <p>
      {{ 'reachOutCollectDocuments' | translate }}
    </p>
  </div>

  <div class="simpleSteps">
    <h1 class="coverAllBusiness">
      3
    </h1>
    <img src="../../../assets/images/borrower/green_arrow.png" class="greenArrow" />
    <p>
      {{ 'replyWithDecision' | translate }}
    </p>
  </div>

  <div class="simpleSteps">
    <h1 class="coverAllBusiness">
      4
    </h1>
    <img src="../../../assets/images/borrower/green_arrow.png" class="greenArrow" />
    <p>
      {{ 'signContracts' | translate }}
    </p>
  </div>
</div>

<h1 style="color:#10194E; font-weight: 700; font-size: 36px; line-height: 54px; text-align: center; margin-top: 3%;">
  {{ 'termFinanceEligibilityTitle' | translate }}
</h1>
<h4 style="text-align: center;">{{ 'termFinanceByCrowd' | translate }}<span style="color: #71DBAC;">{{'X' | translate}}</span></h4>


<form [formGroup]="form" class="flex flex-column" id="financeForm"
  >

  <div>
    <input type="text" formControlName="firstName" class="termFinanceinput"
      placeholder="{{ 'firstNamePlaceholder' | translate }}">
    <div *ngIf="submitted && form.get('firstName')?.errors?.required" class="error text-red-400 mt-2 ">{{ 'firstNameError' | translate }}
    </div>
  </div>

  <div class="mt-4">
    <input type="text" formControlName="lastName" class="termFinanceinput"
      placeholder="{{ 'lastNamePlaceholder' | translate }}">
    <div *ngIf="submitted && form.get('lastName')?.errors?.required" class="error text-red-400 mt-2 ">{{ 'lastNameError' | translate }}
    </div>
  </div>

  <div class="mt-4">
    <input type="email" formControlName="email" class="termFinanceinput"
      placeholder="{{ 'emailPlaceholder' | translate }}">
    <div *ngIf="submitted && form.get('email')?.errors?.required" class="error text-red-400 mt-2 ">{{ 'emailRequiredError' | translate }}
    </div>
    <div *ngIf="submitted && form.get('email')?.errors?.email" class="error text-red-400 mt-2 ">{{ 'emailFormatError' | translate }}</div>
  </div>

  <div class="mt-4">
    <input type="number" formControlName="phoneNumber" class="termFinanceinput no-spinner"
      placeholder="{{ 'phoneNumberPlaceholder' | translate }}">
    <div *ngIf="submitted && form.get('phoneNumber')?.errors?.required" class="error text-red-400 mt-2 ">{{ 'phoneNumberRequiredError' |
      translate }}</div>
    <div *ngIf="submitted && form.get('phoneNumber')?.errors?.invalidPhoneNumber" class="error text-red-400 mt-2 ">{{
      'invalidPhoneNumberError' | translate }}</div>
  </div>

  <div class="mt-4">
    <input type="text" formControlName="companyName" class="termFinanceinput"
      placeholder="{{ 'companyNamePlaceholder' | translate }}">
    <div *ngIf="submitted && form.get('companyName')?.errors?.required" class="error text-red-400 mt-2 ">{{ 'companyNameError' | translate
      }}</div>
  </div>

  <div class="mt-4">
    <input type="text" formControlName="revenueSize" class="termFinanceinput"
      placeholder="{{ 'revenueSizePlaceholder' | translate }}">
    <div *ngIf="submitted && form.get('revenueSize')?.errors?.required" class="error text-red-400 mt-2 ">{{ 'revenueSizeError' | translate
      }}</div>
  </div>

  <div class="mt-4">
    <input type="text" formControlName="fundsFor" class="termFinanceinput"
      placeholder="{{ 'fundsForPlaceholder' | translate }}">
    <div *ngIf="submitted && form.get('fundsFor')?.errors?.required" class="error text-red-400 mt-2 ">{{ 'fundsForError' | translate }}
    </div>
  </div>

  <button type="button" (click)="submitForm()" [style.background-color]="'#22C55E'"
    style="color: white; width: 100px; height: 50px; border-radius: 6px; padding: 1%; border: none; cursor: pointer; margin-top: 4%;">
    {{ 'submitButton' | translate }}
  </button>
</form>


<app-downloadapp></app-downloadapp>